import React from "react";
import "./HistoryDetail.css";
import SectionHeader from "../../../layouts/SectionHeader";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { amountAddSpace } from "../../../utils/utils";
import { STATIC_URL } from "../../../constants/constant";
import HistoryCardStatus from "../HistoryCard/HistoryCardStatus";
import CDevider from "../../../components/CDevider";
import moment from "moment";
import { IHistoryCardProps } from "../HistoryCard/HistoryCard.types";

const HistoryDetail: React.FC = () => {
  const location = useLocation();
  const history = JSON.parse(
    location.state?.history
  ) as IHistoryCardProps["history"];

  const { t } = useTranslation();

  let img = `${STATIC_URL}/img/services/${
    history?.payment?.provider_id || 495
  }`;

  if (history?.type !== "payment") {
    img = `${STATIC_URL}/img/paysys/${history?.paySystemId || 1}`;
  }

  const imgUrl =
    history?.type === "p2p"
      ? `${STATIC_URL}/img/servcats/card.png`
      : `${img}/100x100.webp`;

  const amount = React.useMemo(() => {
    let amount = amountAddSpace(Number(history?.amountCommission));

    return history?.refunded || false
      ? `${amount} ${history?.currencySign || t("balance.sum")}`
      : !history?.isCredit
      ? `-${amount} ${history?.currencySign || t("balance.sum")}`
      : `+${amount} ${history?.currencySign || t("balance.sum")}`;
  }, [
    history?.amountCommission,
    history?.currencySign,
    history?.isCredit,
    history?.refunded,
    t,
  ]);

  return (
    <>
      <SectionHeader title={t("history.history")} />

      <div className="historyDetail wrapper">
        <div className="historyDetail__content">
          <img
            src={imgUrl}
            alt={history?.receiverName}
            className="historyDetail__img"
          />

          <h2 className="historyDetail__amount">{amount}</h2>

          <HistoryCardStatus status={history?.status} />

          <h3 className="historyDetail__receiver">{history?.receiverName}</h3>

          <CDevider />
        </div>

        <div className="historyDetail__info">
          <div className="historyDetail__item">
            <p className="historyDetail__item__label">
              {t("history.cardFrom")}:
            </p>
            <p className="historyDetail__item__value">{history?.cardFrom}</p>
          </div>

          <div className="historyDetail__item">
            <p className="historyDetail__item__label">
              {t("history.prifited")}:
            </p>
            <p className="historyDetail__item__value">
              {amount.replace(/^[-+]/, "")}
            </p>
          </div>

          <div className="historyDetail__item">
            <p className="historyDetail__item__label">{t("history.data")}:</p>
            <p className="historyDetail__item__value">
              {moment(history?.trnDate).format("DD-MM-YYYY")}
            </p>
          </div>

          <div className="historyDetail__item">
            <p className="historyDetail__item__label">{t("history.time")}:</p>
            <p className="historyDetail__item__value">
              {moment(history?.trnDate).format("HH:mm")}
            </p>
          </div>

          <div className="historyDetail__item">
            <p className="historyDetail__item__label">
              {t("history.transactionNumber")}:
            </p>
            <p className="historyDetail__item__value">
              {history?.transactionNumber}
            </p>
          </div>

          {history?.merchantId && (
            <div className="historyDetail__item">
              <p className="historyDetail__item__label">
                {t("history.merchant")}:
              </p>
              <p className="historyDetail__item__value">
                {history?.merchantId}
              </p>
            </div>
          )}

          {history?.terminalId && (
            <div className="historyDetail__item">
              <p className="historyDetail__item__label">
                {t("history.terminal")}:
              </p>
              <p className="historyDetail__item__value">
                {history?.terminalId}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HistoryDetail;
