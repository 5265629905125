import { makeAutoObservable } from "mobx";
import apiClient from "../services/apiClient";

class PointStore {
  inviteCache = null;
  amountCache = null;
  friendsCache = null;
  tasksCache = null;
  awardsHistoryCache = null;

  constructor() {
    makeAutoObservable(this);
  }

  getInvite = async () => {
    if (this.inviteCache) {
      return this.inviteCache;
    }

    try {
      const response = await apiClient.get("/points/invite");

      this.inviteCache = response.data;

      return response.data;
    } catch (error) {
      console.log("Error in getInvite", error);
    }
  };

  getAmount = async () => {
    if (this.amountCache) {
      return this.amountCache;
    }

    try {
      const response = await apiClient.get("/points/amount");

      this.amountCache = response.data;

      return response.data;
    } catch (error) {
      console.log("Error in getAmount", error);
    }
  };

  friends = async () => {
    if (this.friendsCache) {
      return this.friendsCache;
    }

    try {
      const response = await apiClient.get("/points/friends");

      this.friendsCache = response.data;

      return response.data;
    } catch (error) {
      console.log("Error in friends", error);
    }
  };

  tasks = async () => {
    if (this.tasksCache) {
      return this.tasksCache;
    }

    try {
      const response = await apiClient.get("/points");

      this.tasksCache = response.data;

      return response.data;
    } catch (error) {
      console.log("Error in tasks", error);
    }
  };

  awardsHistory = async () => {
    if (this.awardsHistoryCache) {
      return this.awardsHistoryCache;
    }

    try {
      const response = await apiClient.get("/points/awardsHistory");

      this.awardsHistoryCache = response.data;

      return response.data;
    } catch (error) {
      console.log("Error in awardsHistory", error);
    }
  };
}

export const pointStore = new PointStore();
