import React from "react";
import "./Chat.css";
import { useTranslation } from "react-i18next";

const Chat: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="chat wrapper">
      <h2 className="chat__intro">{t("chat.introText")}</h2>
    </div>
  );
};

export default Chat;
