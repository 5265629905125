import React from "react";
import "./CStatusTicket.css";
import { CStatusTicketProps } from "./CStatusTicket.types";
import { useTranslation } from "react-i18next";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoMdCloseCircleOutline } from "react-icons/io";

const CStatusTicket: React.FC<CStatusTicketProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div className="cstatusticket">
      {status === "done" && (
        <div className="cstatusticket__done">
          <FaRegCircleCheck className="cstatusticket__done__icon" />
          <p className="cstatusticket__done__message">{t("labels.success")}</p>
        </div>
      )}

      {status === "error" && (
        <div className="cstatusticket__error">
          <IoMdCloseCircleOutline className="cstatusticket__error__icon" />
          <p className="cstatusticket__error__message">{t("error.repeatLater")}</p>
        </div>
      )}
    </div>
  );
};

export default CStatusTicket;
