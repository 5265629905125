import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./Cards.css";
import { EffectCoverflow, Pagination } from "swiper/modules";
import CCard from "../../../components/CCard";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsPlusCircle } from "react-icons/bs";
import { themeStore } from "../../../stores/themeStore";
import useFetchCards from "./useFetchCards";
import humo from "../../../assets/images/humo.webp";

const Cards: React.FC = () => {
  const { cards, isLoading } = useFetchCards();
  const { t } = useTranslation();

  const getBackgroundStyle = () => ({
    background: themeStore.isDarkTheme
      ? "rgba(0, 0, 0, 0.20)"
      : "rgba(255, 255, 255, 0.15)",
  });

  return (
    <div className="cards">
      <div className="cards__header">
        <Link to="/payment/allcards">{t("labels.allCards")}</Link>
        <Link to="/profile/ident">{t("labels.ident")}</Link>
      </div>

      <Swiper
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView="auto"
        modules={[EffectCoverflow, Pagination]}
      >
        {isLoading && <SwiperSlide className="card__loading"></SwiperSlide>}

        {cards?.map((card) => (
          <SwiperSlide key={card.id}>
            <CCard card={card} />
          </SwiperSlide>
        ))}

        <SwiperSlide className="card__add">
          <Link
            to="/payment/addcard"
            style={getBackgroundStyle()}
            className="card__add__link"
          >
            <BsPlusCircle />
            <p>{t("labels.addCard")}</p>
          </Link>

          <Link
            to="/payment/createVirtualCard"
            style={getBackgroundStyle()}
            className="card__add__link"
          >
            <img src={humo} alt="Humo" className="card__add__img" />
            <p>{t("virtualCard.create")}</p>
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Cards;
