import React from "react";
import "./NotFound.css";

const NotFound: React.FC = () => {
  return (
    <div className="notfound wrapper">
      <h2 className="notfound__title">404</h2>
      <h3 className="notfound__subtitle">Page not found</h3>
    </div>
  );
};

export default NotFound;
