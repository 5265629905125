import React, { useState } from "react";
import "./TransferOffer.css";
import SectionHeader from "../../../layouts/SectionHeader";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import CButton from "../../../components/CButton";
import { accountStore } from "../../../stores/accountStore";

const TransferOffer: React.FC = () => {
  const [agree, setAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setAgree(!agree);
  };

  const acceptOffer = async () => {
    try {
      setIsLoading(true);

      const response = await accountStore.acceptOffer();

      if (response) {
        navigate("/transfer");
      }
    } catch (error) {
      console.log("Error in acceptOffer ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SectionHeader title={t("profile.termsAndConditions")} />

      <div className="transferOffer wrapper">
        <div className="transferOffer__content">
          <h2 className="transferOffer__content__title">
            Приложение № 1 к Правилам комплексного банковского обслуживания
            физических лиц в АО «UZUM BANK»
          </h2>
          <h3 className="transferOffer__content__subtitle">
            ПОРЯДОК ОСУЩЕСТВЛЕНИЯ ТРАНСГРАНИЧНЫХ ПЕРЕВОДОВ
          </h3>

          <p className="transferOffer__content__text">
            1. ДОПОЛНИТЕЛЬНЫЕ ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ.
          </p>
          <p className="transferOffer__content__text__desc">
            1.1. В настоящем Порядке используются следующие термины и
            определения:
          </p>
          <p className="transferOffer__content__text__desc">
            Авторизация – процедура запроса и получения ответа на такой запрос в
            виде разрешения или запрета на проведение операции с использованием
            Карты или ее реквизитов от Банка-эмитента Карты или от Платежной
            системы.
          </p>
          <p className="transferOffer__content__text__desc">
            Аутентификация – процедура дополнительной проверки реквизитов Карты,
            осуществляемая с целью снижения рисков проведения мошеннической
            операции по Карте по Технологии 3D Secure и/или по Технологии
            CVC2/CVV2/ППК2 кода.
          </p>
          <p className="transferOffer__content__text__desc">
            Банк-нерезидент – банк, созданный и осуществляющий свою деятельность
            в соответствии с законодательством государства, отличного от
            Республики Узбекистан;
          </p>
          <p className="transferOffer__content__text__desc">
            Банк-резидент – банк, созданный и осуществляющий свою деятельность в
            соответствии с законодательством Республики Узбекистан;
          </p>
          <p className="transferOffer__content__text__desc">
            Банк –- АО «UZUM BANK» - кредитная организация, которая является
            получателем денежных средств или отправителем денежных средств,
            обслуживающая Плательщика, инициирующего Перевод.
          </p>
          <p className="transferOffer__content__text__desc">
            Банк-эквайрер – термин используется в значении, определенном
            правилами Платежных систем
          </p>
          <p className="transferOffer__content__text__desc">
            Договор - договор о предоставлении Клиенту Услуги, состоящий из
            настоящего порядка осуществления трансграничных переводов и Правил.
          </p>
          <p className="transferOffer__content__text__desc">
            Карта плательщика - банковская платежная карта, выпущенная на имя
            Плательщика, с которой осуществляется Перевод;
          </p>
          <p className="transferOffer__content__text__desc">
            Карта получателя - банковская платежная карта, выпущенная на имя
            Получателя, на которую осуществляется Перевод;
          </p>
          <p className="transferOffer__content__text__desc">
            Клиент – физическое лицо, заключившие с Банком настоящий Договор и
            которому Банк оказывает Услугу.
          </p>
          <p className="transferOffer__content__text__desc">
            Мобильное приложение – мобильное приложение АО «UZUM BANK», в
            котором Клиент передает Распоряжения на осуществление Переводов;
          </p>
          <p className="transferOffer__content__text__desc">
            Перевод (Трансграничный перевод) - один из следующих видов операций,
            осуществляемый Банком по поручению Клиента на основании Распоряжений
            об осуществлении Перевода, даваемых Клиентом посредством Мобильного
            приложения:
          </p>
          <p className="transferOffer__content__text__desc">
            операция по перечислению денежных средств с Карты плательщика
            Платежных систем, эмитированной Банком-резидентом, на Карту
            получателя, эмитированную Банком-нерезидентом;
          </p>
          <p className="transferOffer__content__text__desc">
            операция по перечислению денежных средств с Карты плательщика,
            эмитированной Банком-нерезидентом, на Карту получателя Платежных
            систем, эмитированную Банком-резидентом;
          </p>
          <p className="transferOffer__content__text__desc">
            В рамках настоящего Договора термины «Перевод» и «Трансграничный
            перевод» имеют равнозначное значение.
          </p>
          <p className="transferOffer__content__text__desc">
            Платежная система (ПС) - платежная система, карты которой
            принимаются в Сервисе в целях осуществления Перевода.
          </p>
          <p className="transferOffer__content__text__desc">
            Плательщик - физическое лицо, являющееся отправителем Перевода.
          </p>
          <p className="transferOffer__content__text__desc">
            Получатель - физическое лицо, являющееся получателем Перевода.
          </p>
          <p className="transferOffer__content__text__desc">
            Правила - Правила комплексного банковского обслуживания физических
            лиц в AO «UZUM BANK», размещенные на сайте Банка в сети Интернет
            https://uzumbank.uz.
          </p>
          <p className="transferOffer__content__text__desc">
            Распоряжение об осуществлении Перевода (Распоряжение) - электронный
            документ, формируемый Клиентом в виде информационного сообщения,
            передаваемого в Банк посредством Мобильного приложения, содержащий
            необходимые реквизиты для осуществления Перевода;
          </p>
          <p className="transferOffer__content__text__desc">
            Реквизиты – реквизиты Перевода, приведенные в Приложении 1 к
            настоящему Порядку.
          </p>
          <p className="transferOffer__content__text__desc">
            Стороны Договора (Стороны) - Банк и Клиент.
          </p>
          <p className="transferOffer__content__text__desc">
            Сумма перевода - объем денежных средств, подлежащих переводу Банком
            по поручению Клиента от Плательщика в пользу Получателя в
            соответствии с реквизитами, предоставленными Плательщиком и/или
            Получателем в Распоряжении об осуществлении Перевода. Максимальный
            размер Суммы Денежного Перевода ограничен лимитами в соответствии с
            Приложением 2 к настоящему Порядку;
          </p>
          <p className="transferOffer__content__text__desc">
            Тарифы – стоимость осуществления Переводов, публикуемая на сайте
            Банка в сети Интернет https://uzumbank.uz;
          </p>
          <p className="transferOffer__content__text__desc">
            Услуга - банковская услуга, оказываемая Банком Клиенту в рамках
            Договора, заключающаяся в организации и осуществлении
            Трансграничного перевода денежных средств с использованием
            реквизитов Карты плательщика и Карты получателя в порядке и на
            условиях, установленных настоящим Договором;
          </p>
          <p className="transferOffer__content__text__desc">
            Технология 3D Secure – технология, являющаяся частью программ
            платежных систем, позволяющая Банку-эмитенту однозначно осуществить
            аутентификацию Карты, совершающей операцию в сети Интернет, и
            снизить риск мошенничества, защищая банковскую карту от
            несанкционированного использования
          </p>
          <p className="transferOffer__content__text__desc">
            1.2. Иные термины и определения, используемые в настоящем Порядке,
            имеют то же значение, что и в Правилах.
          </p>

          <p className="transferOffer__content__text">2. ПРЕДМЕТ ДОГОВОРА.</p>
          <p className="transferOffer__content__text__desc">
            2.1. Настоящий Порядок устанавливает процедуру заключения и
            исполнения Договора о предоставлении Клиенту Услуг по
            трансграничному переводу денежных средств.
          </p>
          <p className="transferOffer__content__text__desc">
            2.2. Услуга по трансграничному переводу денежных средств в Мобильном
            Приложении Банка доступна только Клиентам, прошедшим процедуру
            Идентификации в порядке, предусмотренном Правилами.
          </p>
          <p className="transferOffer__content__text__desc">
            2.3. Для заключения Договора Клиент посредством Мобильного
            приложения формирует Распоряжение на осуществление Перевода денежных
            средств со Карты Плательщика на Карту Получателя.
          </p>
          <p className="transferOffer__content__text__desc">
            2.4. Договор считается заключенным посредством Мобильного приложения
            с момента акцепта Банком Распоряжения Клиента путем списания
            денежных средств Банком с Карты плательщика и зачисления суммы
            Перевода на Карту получателя.
          </p>

          <p className="transferOffer__content__text">
            3. ПОРЯДОК СОВЕРШЕНИЯ ПЕРЕВОДА
          </p>
          <p className="transferOffer__content__text__desc">
            3.1. Для совершения Переводов Клиент формирует Распоряжения путем
            последовательного совершения следующих действий:
          </p>
          <p className="transferOffer__content__text__desc">
            - ознакомление с текстом настоящих Порядка, дает предусмотренные
            формой платежной страницы согласия;
          </p>
          <p className="transferOffer__content__text__desc">
            - авторизация Клиента в Мобильном приложении посредством введения
            аутентификационных данных;
          </p>
          <p className="transferOffer__content__text__desc">
            - введение Клиентом Реквизитов путем заполнения полей в интерфейсе
            Мобильного приложения;
          </p>
          <p className="transferOffer__content__text__desc">
            - подтверждение Клиентом параметров Перевода посредством ввода
            ОТП-кода, 3D Secure кода или иным способом в соответствии с
            функциональностью Мобильного приложения.
          </p>
          <p className="transferOffer__content__text__desc">
            3.2. На основании Распоряжения об осуществлении Перевода Клиент дает
            Банку распоряжение о списании с Карты плательщика денежных средств:
          </p>
          <p className="transferOffer__content__text__desc">
            - Суммы Перевода в пользу Получателя;
          </p>
          <p className="transferOffer__content__text__desc">
            - Комиссии, причитающейся Банку за оказание Услуги, связанной с
            совершением Перевода, в соответствии с Тарифами (в случае взимания
            таковой).
          </p>
          <p className="transferOffer__content__text__desc">
            3.3. При формировании и направлении Распоряжения на осуществление
            Перевода с использованием банковской (платежной) карты Клиент
            обязуется в полном объеме выполнять требования Банка в отношении
            осуществления расчетов с использованием банковских (платежных) карт,
            а также правил и требований ПС.
          </p>
          <p className="transferOffer__content__text__desc">
            3.4. С момента передачи в Банк Распоряжения об осуществлении
            Перевода Банк принимает на себя обязательства по оказанию
            Плательщику Услуги. Банк осуществляет Перевод в порядке и на
            условиях, установленных действующим законодательством, правилами ПС
            и настоящим Порядком.
          </p>
          <p className="transferOffer__content__text__desc">
            3.5. Плательщик несет ответственность за правильность информации,
            указанной в Распоряжении об осуществлении Перевода и не вправе
            предъявлять к Банку каких-либо претензий в связи с ненадлежащим
            исполнением Банком своих обязательств по Договору в случае указания
            Клиентом неверных Реквизитов в Распоряжении об осуществлении
            Перевода.
          </p>
          <p className="transferOffer__content__text__desc">
            3.6. Для осуществления Перевода Банк вправе привлекать третьих
            лиц-резидентов и нерезидентов Республики Узбекистан, в том числе
            банки-посредники и поставщиков платежных услуг и технологических
            решений.
          </p>
          <p className="transferOffer__content__text__desc">
            3.7. Распоряжение исполняется Банком в размере Суммы Перевода,
            указанной в Распоряжении. При этом Сумма Перевода не может превышать
            лимиты, установленные Банком, банками-корреспондентами и ПС.
          </p>
          <p className="transferOffer__content__text__desc">
            3.8. Осуществление Перевода подтверждается Банком путем размещения
            уведомления о совершении Перевода в Мобильном приложении.
          </p>
          <p className="transferOffer__content__text__desc">
            3.9. Обязательства Банка перед Плательщиком считаются исполненными в
            полном объеме с момента зачисления денежных средств в Сумме перевода
            на Карту получателя.
          </p>
          <p className="transferOffer__content__text__desc">
            3.10. В случае если Перевод будет возвращен Получателем как
            ошибочный или в случае отказа Получателя от получения Перевода или
            по любым иным причинам, лежащим вне сферы контроля Банка, Банк
            осуществляет возврат денежных средств Плательщику в течение 15
            (пятнадцати) рабочих дней с момента получения Банком денежных
            средств от банка-нерезидента или иного участвующего в процессе
            оказания услуги поставщика платежных услуг путем зачисления
            возвращенных денежных средств по реквизитам Карты плательщика, с
            которой было совершено списание денежных средств в целях
            осуществления Перевода. При этом:
          </p>
          <p className="transferOffer__content__text__desc">
            - сумма комиссии, оплаченная Клиентом за осуществление Перевода в
            соответствии с Тарифами (в случае ее наличия), возврату не подлежит;
          </p>
          <p className="transferOffer__content__text__desc">
            - если валюта Карты получателя отличается от валюты Карты
            плательщика и при этом в процессе Перевода и последующего возврата
            денежных средств была совершена одна или несколько валютообменных
            операций, повлекших образование отрицательных курсовых разниц, сумма
            возвращаемых Плательщику денежных средств уменьшается на сумму таких
            курсовых разниц.
          </p>
          <p className="transferOffer__content__text__desc">
            3.11. При приеме к исполнению Распоряжения, Банк осуществляет
            действия, направленные на удостоверение права Плательщика
            распоряжаться Картой плательщика и контроль достаточности денежных
            средств на Карте плательщика.
          </p>
          <p className="transferOffer__content__text__desc">
            3.12. Банк вправе отказать Плательщику в оказании Услуги в случае:
          </p>
          <p className="transferOffer__content__text__desc">
            - отсутствия у Банка технической возможности для оказания конкретной
            Услуги (совершения конкретного Перевода, включая отсутствие
            возможности осуществления Перевода в определенную страну
            назначения);
          </p>
          <p className="transferOffer__content__text__desc">
            - отсутствия у Банка разрешения на проведение операции от
            банка-эмитента Карты плательщика, и/или банка-эмитента Карты
            получателя, и/или обслуживающих их ПС и/или банка-нерезидента;
          </p>
          <p className="transferOffer__content__text__desc">
            - наличия прямого запрета на проведение операции, предусмотренного
            Договором, и/или договором (соглашением) между Клиентом и
            банком-эмитентом Карты плательщика, и/или правилами ПС, и/или
            действующим законодательством Республики Узбекистан или иным
            применимым правом;
          </p>
          <p className="transferOffer__content__text__desc">
            - превышения запрошенной Суммой перевода лимитов, установленных в
            Приложении 2 к настоящему Договору;
          </p>
          <p className="transferOffer__content__text__desc">
            - выявления операций Плательщика, содержащих признаки подозрительных
            операций, либо операций, несущих репутационные риски для Банка, а
            также, если у Банка возникли подозрения в том, что операция
            осуществляется с нарушением требований законодательства Республики
            Узбекистан либо страны Банка-нерезидента.
          </p>
          <p className="transferOffer__content__text__desc">
            3.13. При совершении:
          </p>
          <p className="transferOffer__content__text__desc">
            - операции по перечислению денежных средств с Карты плательщика,
            эмитированной Банком-резидентом, на Карту получателя, эмитированную
            Банком-нерезидентом, валютой списания с Карты плательщика является
            узбекский сум, валютой зачисления на Карту получателя является
            валюта счета, к которому выпущена Карта получателя;
          </p>
          <p className="transferOffer__content__text__desc">
            - операции по перечислению денежных средств с Карты плательщика,
            эмитированной Банком-нерезидентом, на Карту получателя Платежных
            систем, эмитированную Банком-резидентом, валютой списания с Карты
            плательщика является валюта счета, к которому выпущена Карта
            плательщика, валютой зачисления на Карту получателя является
            узбекский сум;
          </p>
          <p className="transferOffer__content__text__desc">
            3.14. Поскольку валюта списания отличается от валюты зачисления, в
            процессе Перевода Банк совершает с Клиентом валютообменную операцию
            по приобретению суммы валюты, списываемой с Карты отправителя, в
            обмен на сумму валюты, зачисляемую на Карту получателя. При этом до
            отправки Перевода продаваемая (списываемая) и приобретаемая
            (зачисляемая) суммы валют отображаются в интерфейсе Мобильного
            приложения. Применяемый курс конверсии определяется Банком. В момент
            формирования Распоряжения Клиент подтверждает свое согласие с курсом
            конверсии.
          </p>
          <p className="transferOffer__content__text__desc">
            3.15. В процессе выполнения Перевода возможно совершение возврата
            Перевода по инициативе Получателя с согласия Банка и банка-эмитента
            Карты получателя. Под возвратом понимается новый Перевод,
            совершаемый Плательщиком в адрес Получателя в целях возврата
            денежных средств, ранее полученных первым от второго. Для возврата
            полученного Перевода Получатель должен обратиться в банк,
            эмитировавший Карту получателя. Банк оставляет за собой право
            отказать в совершении возврата Перевода в следующих случаях:
          </p>
          <p className="transferOffer__content__text__desc">
            - при отсутствии согласия банка-эмитента Карты получателя;
          </p>
          <p className="transferOffer__content__text__desc">
            - при отсутствии технической возможности проведения отмены;
          </p>
          <p className="transferOffer__content__text__desc">
            - если с момента совершения первоначального Перевода прошло более 15
            (пятнадцати) дней.
          </p>
          <p className="transferOffer__content__text__desc">
            3.16. Банк оставляет за собой право в любое время приостановить
            оказание услуги с предварительным уведомлением Клиента за 1 (один)
            час способами, указанными в Правилах.
          </p>
          <p className="transferOffer__content__text__desc">
            3.17. При совершении Перевода из Российской Федерации Плательщик
            подтверждает, что
          </p>
          <p className="transferOffer__content__text__desc">
            - При совершении Перевода он использует Карту, выпущенную на его
            имя;
          </p>
          <p className="transferOffer__content__text__desc">
            - Плательщик не использует Карты, выпущенные для совершения операций
            юридическими лицами;
          </p>
          <p className="transferOffer__content__text__desc">
            - Перевод осуществляется им для личных нужд и не связан с ведением
            предпринимательской деятельности или частной практики Плательщиком
            или получателем;
          </p>
          <p className="transferOffer__content__text__desc">
            - Плательщик/ получатель не является резидентом иностранных
            государств и территорий, совершающих в отношении Российской
            Федерации, российских юридических лиц и физических лиц
            недружественные действия в соответствии с Распоряжение Правительства
            РФ от 05.03.2022 N 430-р (Австралия, Албания, Андорра, Багамские
            Острова, Великобритания, (включая коронные владения Британской
            короны и Британские заморские территории), страны-члены Евросоюза,
            Исландия, Канада, Лихтенштейн, Микронезия, Монако, Новая Зеландия,
            Норвегия, Республика Корея, Сан-Марино, Северная Македония,
            Сингапур, США, Тайвань (Китай), Украина, Черногория, Швейцария,
            Япония);
          </p>
          <p className="transferOffer__content__text__desc">
            - Перевод не связан с
          </p>
          <p className="transferOffer__content__text__desc">
            * предоставлением резидентом в пользу нерезидента кредитов и займов;
          </p>
          <p className="transferOffer__content__text__desc">
            * исполнением обязательств резидентом по займам, предоставленным
            нерезидентом, в объёме, превышающем ₽10 млн. в календарный месяц;
          </p>
          <p className="transferOffer__content__text__desc">
            * расчетами с лицами иностранных государств, совершающих
            недружественные действия (иностранные лица, связанными с
            иностранными государствами, которые совершают в отношении российских
            юридических лиц и физических лиц недружественные действия (в том
            числе если такие иностранные лица имеют гражданство этих государств,
            местом их регистрации, местом преимущественного ведения ими
            хозяйственной деятельности или местом преимущественного извлечения
            ими прибыли от деятельности являются эти государства), и лица,
            которые находятся под контролем указанных иностранных лиц,
            независимо от места их регистрации или места преимущественного
            ведения ими хозяйственной деятельности.), по сделкам (операциям),
            влекущим за собой возникновение права собственности на ценные
            бумаги, недвижимое имущество, по сделкам (операциям), влекущими за
            собой прямо и (или) косвенно установление, изменение или прекращение
            прав владения, пользования и (или) распоряжения долями в уставных
            капиталах обществ с ограниченной ответственностью либо иных прав,
            позволяющих определять условия управления такими обществами с
            ограниченной ответственностью и (или) условия осуществления ими
            предпринимательской деятельности; в рамках исполнения обязательств
            перед иностранными правообладателями, являющимися лицами иностранных
            государств, совершающих недружественные действия;
          </p>
          <p className="transferOffer__content__text__desc">
            * зачислением резидентами иностранной валюты на свои счета (вклады),
            открытые в расположенных за пределами территории Российской
            Федерации банках и иных организациях финансового рынка, а также
            осуществлением перевода денежных средств без открытия банковского
            счета с использованием электронных средств платежа, предоставленных
            иностранными поставщиками платежных услуг при перечислении
            резидентами РФ денежных средств, полученных в виде дивидендов по
            акциям российских акционерных обществ либо при распределении прибыли
            российских обществ с ограниченной ответственностью, хозяйственных
            товариществ и производственных кооперативов;
          </p>
          <p className="transferOffer__content__text__desc">
            * нарушением иных специальных экономических мер, установленных
            Российской Федерацией:
          </p>
          <p className="transferOffer__content__text__desc">
            - Перевод не связан с легализацией (отмыванием) доходов, полученных
            преступным путем, финансированием терроризма и финансированием
            распространения оружия массового уничтожения;
          </p>
          <p className="transferOffer__content__text__desc">
            - Перевод не связан с переводом из/в криптовалюту и иные суррогаты
            денежных средств; с деятельностью по организации и проведению
            лотерей или азартных игр, незаконной деятельностью на финансовом
            рынке либо иной запрещенной или незаконной деятельности;
          </p>
          <p className="transferOffer__content__text__desc">
            - Плательщик/получатель не является лицом, в отношении которого
            применяются специальные экономические меры или принудительные меры,
            в соответствии с Федеральным законом РФ № 281-ФЗ 'О специальных
            экономических мерах и принудительных мерах', Перевод не совершается
            в интересах или в пользу таких лиц;
          </p>
          <p className="transferOffer__content__text__desc">
            - Плательщик не использует Сервис в противоправных целях, нарушающих
            права третьих лиц, а также в целях совершения оплаты товаров, работ,
            услуг, запрещенных к реализации в соответствии с законодательством
            Российской Федерации и законодательством Банка;
          </p>
          <p className="transferOffer__content__text__desc">
            - Перевод совершается в соответствии с требованиями законодательства
            Российской Федерации и предписаний Банка России, требованиями
            законодательства страны регистрации Банка, включая законодательство
            о валютном регулировании;
          </p>
          <p className="transferOffer__content__text__desc">
            - Перевод не связан с перечислением денежных средств в пользу
            получателей, в отношении которых запрещено проведение Переводов.
          </p>
          <p className="transferOffer__content__text__desc">
            В совершении Перевода может быть отказано в одностороннем порядке и
            без объяснения причин, в случае возникновения подозрений в том, что
            операция осуществляется с нарушением требований законодательства
            Российской Федерации, законодательства страны регистрации Банка,
            Плательщика или получателя, правил Платежных систем, или носит
            мошеннический характер.
          </p>
          <p className="transferOffer__content__text__desc">
            3.17.1. Принимая настоящие Условия, Плательщик тем самым дает свое
            согласие на обработку, в том числе автоматизированную, персональных
            данных Плательщика и получателя, включая сбор, систематизацию,
            накопление, хранение, уточнение, использование и передачу сведений о
            нем третьим лицам с целью исполнения Перевода. Все используемые
            понятия и термины, применяются в значениях, определенных в
            Федеральном законе от 27 июля 2006 года № 152-ФЗ «О персональных
            данных».
          </p>
          <p className="transferOffer__content__text__desc">
            3.17.2. В случае, если Плательщик предоставляет для совершения
            Перевода персональные данные третьих лиц, Плательщик гарантирует
            законность их получения и наличие согласия субъектов этих
            персональных данных на их предоставление, а также принимает на себя
            ответственность за правомерность предоставления и соответствие
            действительности указанных данных
          </p>

          <p className="transferOffer__content__text">
            4. ОТВЕТСТВЕННОСТЬ СТОРОН.
          </p>
          <p className="transferOffer__content__text__desc">
            4.1. В случае неисполнения и/или ненадлежащего исполнения своих
            обязательств по Договору, Стороны несут ответственность в
            соответствии с условиями настоящего Порядка и действующим
            законодательством Республики Узбекистан.
          </p>
          <p className="transferOffer__content__text__desc">
            4.2. Банк не несет ответственности за неисполнение и/или
            ненадлежащее исполнение обязательств по Договору, если такое
            неисполнение и/или ненадлежащее исполнение произошло по вине Клиента
            и/или иных участников расчетов.
          </p>
          <p className="transferOffer__content__text__desc">
            4.3. Банк не несет ответственности за ненадлежащее исполнение
            обязательств по Договору, если такое ненадлежащее исполнение связано
            с указанием Клиентом ошибочных Реквизитов в Распоряжении об
            осуществлении Перевода.
          </p>
          <p className="transferOffer__content__text__desc">
            4.4. Клиент несет личную ответственность за конфиденциальность
            параметров Перевода и реквизитов Карты плательщика и/или Карты
            получателя/реквизитов Получателя. Банк не несет ответственности в
            случае разглашения параметров Перевода при отсутствии доказанного
            факта разглашения такой информации со стороны Банка.
          </p>
          <p className="transferOffer__content__text__desc">
            4.5. Банк не несет ответственности за несоблюдение Клиентом
            требований настоящего Договора в отношении правил осуществления
            Перевода и негативные последствия, наступившие для Клиента в связи с
            таким несоблюдением.
          </p>
          <p className="transferOffer__content__text__desc">
            4.6. Банк не несет ответственность за отказ Получателя от получения
            Перевода и действия, совершенные им для возврата Перевода
            Отправителю. В случае возврата Перевода банком-получателем все риски
            и расходы, связанные с таким возвратом, ложатся на Клиента.
          </p>
          <p className="transferOffer__content__text__desc">
            4.7. Банк не несет ответственности за любые убытки, возникшие у
            Клиента в связи тем, что последний не ознакомился и/или
            несвоевременно ознакомился с условиями настоящего Договора и/или
            изменениями и дополнениями, внесенными Банком в Договор и Правила.
          </p>
          <p className="transferOffer__content__text__desc">
            4.8. Банк не несет ответственности в случае, когда Перевод
            осуществлен с нарушением сроков и/или иных требований, установленных
            правилами ПС, Договором и/или действующим законодательством
            Республики Узбекистан, по вине Плательщика, Получателя и/или иных
            участников расчетов.
          </p>
          <p className="transferOffer__content__text">
            5. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
          </p>
          <p className="transferOffer__content__text__desc">
            5.1. Споры и разногласия, вытекающие из Договора, разрешаются в
            порядке, предусмотренном в Правилах.
          </p>
          <p className="transferOffer__content__text__desc">
            5.2. Во всем остальном, что не определено условиями настоящего
            Порядка, Стороны руководствуются Действующим законодательством,
            правилами Платёжных систем, Правилами и Тарифами Банка.
          </p>
          <p className="transferOffer__content__text__desc">
            5.3. При совершении операций из Российской Федерации Банк исходит из
            того, что Банк-эмитент, давший разрешение на проведение операции по
            списанию денежных средств с Карты, надлежащим образом выполнил свои
            обязанности в соответствии с Федеральным законом от 07 августа 2001
            года №115-ФЗ «О противодействии легализации (отмыванию) доходов,
            полученных преступным путем, и финансированию терроризма» по
            идентификации своего клиента – Плательщика.
          </p>
          <p className="transferOffer__content__text">
            6. АДРЕС И РЕКВИЗИТЫ БАНКА
          </p>
          <p className="transferOffer__content__text__desc">
            Акционерное общество «UZUM BANK»
          </p>
          <p className="transferOffer__content__text__desc">
            Адрес (местонахождение): Республика Узбекистан,
          </p>
          <p className="transferOffer__content__text__desc">
            город Ташкент, улица Фидокор, дом 30.
          </p>
          <p className="transferOffer__content__text__desc">
            Телефоны для справок (call center): +998 78 777 07 99
          </p>
          <p className="transferOffer__content__text__desc">
            Официальный сайт Банка в сети Интернет: uzumbank.uz
          </p>
        </div>

        <div>
          <label className="transferOffer__checkbox">
            <input
              type="checkbox"
              checked={agree}
              onChange={handleCheckboxChange}
            />
            <p
              className={`transferOffer__agree__text ${
                agree ? "transferOffer__agree__text-strikethrough" : ""
              }`}
            >
              {t("offer.offer")}
            </p>
          </label>

          <CButton
            title={t("labels.next")}
            disabled={!agree}
            isLoading={isLoading}
            onClick={acceptOffer}
          />
        </div>
      </div>
    </>
  );
};

export default TransferOffer;
