import React, { useState } from "react";
import "./AuthOffer.css";
import SectionHeader from "../../../layouts/SectionHeader";
import { t } from "i18next";
import CButton from "../../../components/CButton";
import { useNavigate } from "react-router-dom";

const AuthOffer: React.FC = () => {
  const [agree, setAgree] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setAgree(!agree);
  };

  return (
    <>
      <SectionHeader title={t("offer.termsAndConditions")} />
      <div className="authOffer wrapper">
        <div className="authOffer__content">
          <h2>ПУБЛИЧНАЯ ОФЕРТА СИСТЕМЫ ЛОЯЛЬНОСТИ АО «PAY-WAY»</h2>

          <p>
            1. ОРГАНИЗАТОР <br />
            Организатором системы лояльности (далее – «Организатор») выступает
            Акционерное Общество «PAY-WAY», платёжная организация, действующая
            под лицензией Центрального банка Республики Узбекистан №27 от
            04.05.2024 года (лицензия №27 от 22.05.2021 года обновлена в связи
            со сменой организационно-правовой формы Компании). <br />{" "}
            Юридический адрес: Республика Узбекистан, 170126, г. Андижан, ул.
            Бобуршох, 2. ИНН: 308283164 <br /> ОКЭД: 62090
          </p>

          <p>
            2. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ <br />
            Система лояльности - маркетинговая программа, направленная на
            стимулирование постоянных клиентов компании с целью увеличения их
            активности и привлечения новых клиентов. Она предоставляет
            участникам вознаграждение в виде баллов за оплату услуг, совершение
            определённых действий в Web2 приложении PAY WAY. <br /> Организатор
            – Акционерное общество «PAY-WAY». <br /> Участник Системы лояльности
            (далее – «Участник») – физическое лицо, являющееся Пользователем
            Web2 приложения PAY WAY, зарегистрированный с помощью принадлежащего
            ему абонентского номера телефона и согласившийся (совершивший
            акцепт) с правилами и условиями использования продуктов АО «PAY-WAY»
            и правилами участия в Системе лояльности. <br /> Реферальная
            программа - возможность увеличить количество заработанных баллов в
            рамках проводимой Системы лояльности, путём приглашения
            Пользователей к участию в Акции с помощью рассылки персональной
            реферальной ссылки (кода приглашения), присваиваемого Участнику
            после акцепта Правил Акции. <br /> Web2 приложение PAY WAY –
            программный продукт АО «PAY-WAY», доступно исключительно через
            платформу Telegram. Для использования всех функций и сервисов
            приложения необходимо наличие установленного и активного аккаунта в
            мессенджере Telegram.
          </p>

          <p>
            3. ПРЕДМЕТ ПУБЛИЧНОЙ ОФЕРТЫ <br />
            3.1. Настоящая Публичная Оферта определяет порядок участия в системы
            лояльности, проводимой в рамках Web2-приложения PAY WAY,
            правообладателем которого является АО «PAY-WAY», в том числе порядок
            действий, требования к участникам, порядок начисления и
            использования начисленных баллов. <br />
            3.2. Цель запуска системы лояльности заключается в привлечении и
            поддержании интереса к новому программному продукту под торговой
            маркой «PAY WAY» (далее – «Сервис»), стимулировании увеличения числа
            идентифицированных Пользователей Сервиса, увеличении узнаваемости
            торговой марки «PAY WAY», поддержания стабильных и долгосрочных
            отношений с пользователями, увеличение их активности. <br /> 3.3.
            Система лояльности не является азартной игрой, лотереей или иной
            формой деятельности, основанной на риске. Участие в системе
            лояльности не предусматривает внесения каких-либо платежей со
            стороны Пользователя Системы. Пользователь не несёт финансовых и
            материальных рисков.
          </p>

          <p>
            4. УСЛОВИЯ ПРОВЕДЕНИЯ СИСТЕМЫ ЛОЯЛЬНОСТИ <br />
            4.1. Программа проводится с 01.10.2024 года и будет действовать до
            официального завершения, о котором будет сообщено дополнительно.
            Организатор оставляет за собой право изменить условия или сроки
            действия программы лояльности, предварительно уведомив участников
            через официальные информационные каналы Организатора. <br />
            4.2. В случае досрочного прекращения проведения Системы лояльности,
            продления сроков ее проведения, или изменения в Правилах,
            соответствующая информация будет размещена на сайте и/или иных
            официальных каналах Организатора. <br />
            4.3. Официальными каналами Организатора признаются включая, но не
            ограничиваясь: <br />
            Сайт: www.1pay.uz <br />
            Мобильное приложение «PAY WAY», установленное с Google Play Market,
            AppStore или AppGallery: https://1pay.uz/app <br />
            Telegram Web2 приложение «PAY WAY» - https://t.me/PayWayApp_bot{" "}
            <br />
            Facebook: https://www.facebook.com/payway.uz <br />
            Instagram: https://www.instagram.com/paywayuz <br />
            Treads: https://www.threads.net/@paywayuz <br />
            Telegram – канал: https://t.me/paywayuz <br />
            Дзен: https://dzen.ru/payway <br />
            4.4. За дополнительной информацией участники Системы лояльности
            могут обратиться в call-центр Организатора по номеру: +998–781220108{" "}
            <br />
            4.5. Место запуска и функционирования системы лояльности –
            Республика Узбекистан. <br />
            4.6. Все сроки, указанные в настоящих правилах, исчисляются по
            времени г. Ташкент (GMT+5), Республики Узбекистан.
          </p>

          <p>
            5. УЧАСТНИКИ СИСТЕМЫ ЛОЯЛЬНОСТИ
            <br />
            5.1. Любое дееспособное физическое лицо, достигшее совершеннолетнего
            возраста (восемнадцать лет) зарегистрированное в Web2 приложении PAY
            WAY, автоматически становится участником программы лояльности.{" "}
            <br />
            5.2. Пользователи, не достигшие совершеннолетнего возраста, не
            получившие документ удостоверяющий личность в соответствии с
            законодательством Республики Узбекистан могут быть допущены к
            участию в Системе лояльности только при условии наличия
            подтверждённого/идентифицированного статуса в Web2 приложении PAY
            WAY, а также с письменного подтверждения согласия на участие в
            Системе лояльности от родителя, опекуна, усыновителя. <br />
            5.3. К участию в Системе лояльности не допускаются: <br />
            • сотрудники Организатора; <br />
            • близкие родственники сотрудников Организатора: супруг, супруга,
            дети, мать, отец, брат, сестра, бабушка, дедушка. <br />
            Что является основанием для отстранения от участия в Системе
            лояльности. <br />
            5.4. Акцепт настоящего Договора достигается посредством ознакомления
            и предоставления согласия Участника системы с пунктами настоящего
            Договора, а также регистрации Пользователя в Web2 приложении PAY
            WAY, что означает полное согласие Пользователя с настоящими
            правилами Системы лояльности. <br />
            5.5. Участники программы обязуются использовать начисленные баллы в
            соответствии с условиями настоящей Оферты. <br />
          </p>

          <p>
            6. ПЕРСОНАЛЬНЫЕ ДАННЫЕ <br />
            6.1. Акцептуя настоящий Договор, участник предоставляет
            неограниченное согласие Организатору на обработку персональной
            информации, в соответствии с требованиями Закона «О персональных
            данных», необходимых для использования услуг и участия в Системе
            лояльности АО «PAY-WAY». <br />
            6.2. Персональная информация участника Системы лояльности АО
            «PAY-WAY» подвержена включая, но не ограничиваясь, сбору, обработке,
            хранению, извлечению, накоплению, удалению, изменению, блокированию,
            обезличиванию, систематизации, распространению, предоставлению,
            уничтожению, передаче третьим лицам в целях исполнения настоящего
            Договора, и строго в соответствии с действующим Законодательством
            Республики Узбекистан и Политикой Конфиденциальности Платежной
            организации АО «PAY-WAY». <br />
            6.3. Акцептуя правила настоящей Оферты, участник обязуется указывать
            достоверные данные, а именно номер мобильного телефона, Ф.И.О, номер
            банковской карты участника Системы лояльности, паспортные данные,
            данные по оплатам и состоянию баланса банковской карты и прочее.
            Весь перечень предоставлен в Политике Конфиденциальности Платежной
            организации АО «PAY-WAY». <br />
          </p>

          <p>
            7. НАЧИСЛЕНИЕ БАЛЛОВ
            <br />
            7.1. Начисление баллов осуществляется за следующие действия,
            производимые в Web2 приложении PAY WAY: <br />
            7.1.1. Перевод денежных средств с карты на карту (P2P-перевод){" "}
            <br />
            7.1.2. При оплате услуг из представленного списка в приложении
            (мобильные операторы, интернет-провайдеры, коммунальные услуги и
            др.) <br />
            7.1.3. При оплате услуг зарубежных мобильных операторов <br />
            7.1.4. Денежный перевод на зарубежную карту <br />
            7.1.5. Пополнение банковской карты с зарубежной карты <br />
            7.1.6. Пополнение игрового баланса из представленного в приложении
            перечня онлайн-игр <br />
            7.1.7. Подтверждённую идентификацию в системе PAY WAY <br />
            7.1.8. Добавление банковской карты в интерфейсе Web2 приложения PAY
            WAY <br />
            7.1.9. Выпуск виртуальной карты в интерфейсе Web2 приложения PAY
            WAY. <br />
            7.2. Количество зачисляемых на бонусный счёт баллов исчисляется в
            процентном соотношении к сумме, на которую была произведена
            транзакция. <br />
          </p>

          <p>
            8. РЕФЕРАЛЬНАЯ ПРОГРАММА В СИСТЕМЕ ЛОЯЛЬНОСТИ <br />
            8.1. В целях вовлечения большей аудитории и стимулирования к
            действию Участники Системы лояльности могут получать дополнительные
            баллы за приглашение новых Пользователей с использованием
            дополнительной возможности «Реферальная ссылка», при условии, что
            новые Пользователи выполнят минимальные условия, указанные в
            настоящей Оферте. <br />
            8.2. Информация о персональной Реферальной ссылке, отображается в
            разделе «Бонусы» Web2 приложения PAY WAY. <br />
            8.3. Число участников, воспользовавшихся персональной Реферальной
            ссылкой участника Системы лояльности для перехода в Web2 приложение
            PAY WAY, а также число Пользователей собравших необходимое
            минимальное количество баллов для зачисления их в счёт при получении
            вознаграждения за добавленных активных пользователей, будет
            отображено в разделе «Бонус» в Web2 приложении PAY WAY. <br />
            8.4. За каждого приглашённого Пользователя с использованием
            персональной Реферальной ссылки Участник получает вознаграждение в
            количестве 500 баллов, при условии, что новый Пользователь заработал
            1000 баллов при выполнении действий в соответствии с п. 5.1, но за
            исключением п. 5.1.7, п. 5.1.8, п. 5.1.9. <br />
            8.5. За каждого 10 приглашённого нового Пользователя с
            использованием персональной Реферальной ссылки Участник получает
            дополнительное вознаграждение в размере 5000 баллов, при условии,
            что каждый такой новый Пользователь заработал 1000 баллов при
            выполнении действий в соответствии с п. 5.1, но за исключением п.
            7.1.7, п. 7.1.8, п. 7.1.9. <br />
            8.6. Баллы за добавление банковской карты, идентификацию и выпуск
            виртуальной карты в интерфейсе Web2 приложения PAY WAY не
            учитываются при расчете минимальной суммы баллов для выполнения
            условий приглашения новых Пользователей с использованием
            персональной Реферальной ссылки в интерфейсе Web2 приложения PAY
            WAY. Для того чтобы начислить Участнику Системы, приглашенный им
            пользователь должен выполнить задания, связанные с реальными
            платежами, чтобы достичь суммы 1000 баллов, не включая бонус за
            выпуск виртуальной карты, идентификации и добавления банковской
            карты в интерфейс Web2 приложения PAY WAY. <br />
          </p>

          <p>
            9. ИСПОЛЬЗОВАНИЕ БАЛЛОВ
            <br />
            9.1. Количество накопленных баллов отображается во вкладке «Бонусы»,
            в личном кабинете Участника системы лояльности Web2 приложения PAY
            WAY. <br />
            9.2. Накопленные баллы могут быть использованы для: <br />
            9.2.1. оплаты услуг из представленного списка в Web2 приложения PAY
            WAY; <br />
            9.2.2. пополнения привязанной банковской карты или виртуальной карты
            в интерфейсе Web2 приложения PAY WAY. <br />
            9.3. Баллы действительны в течение 12 месяцев с момента их
            начисления. <br />
          </p>

          <p>
            10. УСЛОВИЯ И ПОРЯДОК ПОЛУЧЕНИЯ ВОЗНАГРАЖДЕНИЯ <br />
            10.1. Вознаграждение участника Системы лояльности (далее –
            «Вознаграждение») формируется за счет средств Организатора и
            используется исключительно в рамках настоящей Системы лояльности.{" "}
            <br />
            10.2. Организатор оставляет за собой право увеличить и/или уменьшить
            количество начисляемых баллов за выполненные действия в Web2
            приложении PAY WAY, заменить их аналогичными по сущности, виду и/или
            дополнительно включить в Системы лояльности другие
            бонусы/вознаграждения/призы, не предусмотренные настоящими
            Правилами. <br />
            10.3. Вознаграждение зачисляется участнику Системы лояльности на
            виртуальную банковскую карту, открытую в интерфейсе Web2 приложения
            или в мобильном приложении PAY WAY. <br />
            10.4. Минимальный размер Вознаграждения, подлежащий перечислению на
            банковскую/виртуальную карту или оплату услуг, представленных в
            интерфейсе Web2 приложения PAY WAY, составляет 50 000 баллов. <br />
            10.5. Сумма вознаграждения исчисляется в соответствии количеству
            накопленных баллов, то есть 1 балл приравнивается 1 узбекскому суму.{" "}
            <br />
            10.6. Вознаграждение зачисляется если: <br />
            • если Пользователь прошел процедуру идентификации и открыл
            виртуальную карту в интерфейсе Web2 приложения PAY WAY; <br />
            • на момент зачисления вознаграждения банковская карта/виртуальная
            карты активна (не заблокирована); <br />
            • абонентский номер телефона, используемый при регистрации в системе
            PAY WAY активен. <br />
            10.7. Налогообложение лиц, получающих Вознаграждение в рамках
            Системы лояльности АО «PAY-WAY», производится в соответствии с
            требованиями законодательства Республики Узбекистан. Организатор
            самостоятельно и за свой счёт уплачивает индивидуальный подоходный
            налог в качестве налогового агента участника Системы лояльности в
            связи с получением Вознаграждения. <br />
            10.8. Организатор снимает с себя ответственность за осуществление и
            уплату иных платежей и сборов в связи с получением, владением и
            распоряжением Вознаграждения, полученного в рамках Системы
            лояльности. <br />
            10.9. Обязательство Организатора перед участником Системы лояльности
            считается исполненным с момента зачисления вознаграждения на
            банковскую карту/виртуальную карту или проведения оплаты выбранной
            услуги в интерфейсе Web2 приложения PAY WAY. <br />
            10.10. Участник утрачивает возможность получения вознаграждения в
            следующих случаях: <br />• отказ Пользователя в передаче
            Персональных данных Организатору; <br />
            • передаче права получения Вознаграждения третьему лицу; <br />
            • при наличии у Организатора письменного уведомления участника
            Системы лояльности об отказе от получения Вознаграждения; <br />
            • невыполнения или ненадлежащего выполнения участником Системы
            лояльности условий настоящей Оферты; <br />
            • после истечения срока действия Системы лояльности. <br />
            10.11. Пользователь не выразивший намерения получения
            Вознаграждения, начисляемого в рамках Системы лояльности в период её
            действия, не может по истечении срока действия Системы лояльности
            требовать возмещения Вознаграждения. В такой ситуации вознаграждение
            признаётся невостребованным, а право получения Вознаграждения –
            утраченным. <br />
            10.12. В случае выявления финансовых операций, признанных прямо или
            косвенно нарушающих законодательство Республики Узбекистан,
            Организатор вправе отстранить или временно приостановить, до
            выяснения всех обстоятельств, Пользователя от участия в Системе
            лояльности, а также отказать в получении Вознаграждения <br />
          </p>

          <p>
            11. ОТВЕТСТВЕННОСТЬ СТОРОН <br />
            11.1. Организатор не несет ответственности за убытки, возникшие
            вследствие некорректного использования баллов или несоответствия
            действий участников условиям Системы лояльности. <br />
            11.2. Участник обязан соблюдать сохранность своих персональных
            данных и выполнять условия Оферты. <br />
            11.3. Организатор освобождается от ответственности за: <br />
            11.3.1. задержку или отсутствие получения сведений, необходимых для
            получения Вознаграждения, по причинам, не зависящим от Организатора;{" "}
            <br />
            11.3.2. несоблюдение участниками условий Системы лояльности, включая
            своевременное выполнение действий, необходимых для получения
            Вознаграждения; <br />
            11.3.3. незнание участниками условий функционирования Системы
            лояльности; <br />
            11.3.4. любые убытки и расходы, возникшие у участников в связи с
            участием в Системе лояльности; <br />
            11.3.5. обстоятельства непреодолимой силы (форс-мажор). <br />
            11.4. Участники Акции несут ответственность за доступность своих
            мобильных телефонов для входящих звонков и сообщений в течение всего
            периода проведения Системы лояльности. <br />
            11.5. Абонентский номер телефона, зарегистрированный участником
            Системы лояльности в Web2 приложении PAY WAY, должен быть активен и
            принадлежать участнику до окончания проведения Системы лояльности. В
            случае передачи абонентского номера третьим лицам или иных действий,
            приведших к отчуждению абонентского номера, участник утрачивает
            право на дальнейшее участие и получение Вознаграждения. <br />
            11.6. Участники сами покрывают все расходы, связанные с участием в
            Системе лояльности. <br />
            11.7. В случае выявления недобросовестных действий участник будет
            отстранён от участия в Системе лояльности, а набранные баллы -
            аннулированы. <br />
            11.8. Организатор самостоятельно оценивает добросовестность участия,
            основываясь на имеющихся технических возможностях и с учётом
            законодательства Республики Узбекистан и условий Системы лояльности.{" "}
            <br />
            11.9. Любые разногласия, возникшие в связи с исполнением правил
            настоящей Публичной оферты, должны быть решены путем переговоров на
            основании письменного запроса. Сроки рассмотрения запроса и
            требования к его содержанию регулируются законодательством
            Республики Узбекистан. <br />
            11.10. Если спор не удастся разрешить путем переговоров, он подлежит
            рассмотрению в экономическом суде г. Ташкент в соответствии с
            действующим законодательством Республики Узбекистан <br />
          </p>

          <p>
            12. ЗАКЛЮЧИТЕЛЬНЫЕ УСЛОВИЯ <br />
            12.1. По вопросам, не урегулированным настоящими Правилами,
            Организатор и Участники руководствуются действующим
            законодательством Республики Узбекистан. <br />
            12.2. Ответственность сторон и порядок урегулирования споров
            определяются в соответствии с нормами действующего законодательства
            Республики Узбекистан. <br />
            12.3. Действие настоящих Правил распространяется на весь период
            проведения Акции. <br />
            12.4. Публичная оферта составлена и опубликована на русском языке.{" "}
            <br />
            12.5. Настоящая оферта действительна до даты окончания Системы
            лояльности. <br />
          </p>
        </div>

        <div>
          <label className="authOffer__checkbox">
            <input
              type="checkbox"
              checked={agree}
              onChange={handleCheckboxChange}
            />
            <p
              className={`authOffer__agree__text ${
                agree ? "authOffer__agree__text-strikethrough" : ""
              }`}
            >
              {t("offer.offer")}
            </p>
          </label>

          <CButton
            title={t("labels.next")}
            disabled={!agree}
            onClick={() => navigate("/auth/register")}
          />
        </div>
      </div>
    </>
  );
};

export default AuthOffer;
