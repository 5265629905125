import React from "react";
import "./CChooseService.css";
import { ICChooseServiceProps } from "./CChooseService.types";
import { BsThreeDots } from "react-icons/bs";
import { STATIC_URL } from "../../constants/constant";

const CChooseService: React.FC<ICChooseServiceProps> = ({ service }) => {
  return (
    <div className="cchooseservice">
      <img
        src={`${STATIC_URL}/img/services/${service.id}/100x100.webp`}
        alt={service.name}
        className="cchooseservice__img"
      />

      <p className="cchooseservice__title">{service.name}</p>

      <BsThreeDots />
    </div>
  );
};

export default CChooseService;
