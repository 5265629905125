import React, { useEffect, useState } from "react";
import "./Profile.css";
import Support from "./Support";
import { useTranslation } from "react-i18next";
import CSwitch from "../../components/CSwitch";
import { themeStore } from "../../stores/themeStore";
import { GoSun } from "react-icons/go";
import { observer } from "mobx-react-lite";
import { FaRegUserCircle } from "react-icons/fa";
import { IoExitOutline, IoMoonOutline } from "react-icons/io5";
import { CiSettings } from "react-icons/ci";
import { IoIosArrowForward } from "react-icons/io";
import { FaRegUser } from "react-icons/fa6";
import { AiOutlineFileProtect } from "react-icons/ai";
import { GrMapLocation } from "react-icons/gr";
import { Link } from "react-router-dom";
import CDevider from "../../components/CDevider";
import { accountStore } from "../../stores/accountStore";
import { IAccountInfo } from "../../models/IAccountInfo";
import CModal from "../../components/CModal";
import CButton from "../../components/CButton";
import { TG_USER_ID } from "../../constants/constant";

const Profile: React.FC = observer(() => {
  const [accountInfo, setAccountInfo] = useState<IAccountInfo>();
  const [isIdent, setIsIdent] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { t } = useTranslation();

  const fetchAccountInfo = async () => {
    try {
      const response = await accountStore.getAccountInfo();

      setAccountInfo(response);

      return response;
    } catch (error) {
      console.log("Error in fetchAccountInfo", error);
    }
  };

  const fetchIsIdent = async () => {
    try {
      const response = await accountStore.isIdentAccount();

      setIsIdent(response);

      return response;
    } catch (error) {
      console.log("Error in fetchIsIdent", error);
    }
  };

  const logout = async () => {
    try {
      await accountStore.logoutAccount();

      toggleModal();

      localStorage.removeItem(`token-${TG_USER_ID}`);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log("Error in logout", error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  useEffect(() => {
    fetchAccountInfo();
    fetchIsIdent();
  }, []);

  return (
    <div className="profile wrapper">
      <Support />

      <div>
        <div className="profile__user">
          <FaRegUserCircle className="profile__user__img" />

          <div className="profile__logout" onClick={toggleModal}>
            <IoExitOutline /> <span>{t("labels.logout")}</span>
          </div>
        </div>

        <div className="profile__user">
          <div className="profile__user__title">
            {isIdent ? (
              <p>
                {accountInfo?.lastName ? `${accountInfo.lastName} ` : ""}
                {accountInfo?.firstName ? `${accountInfo.firstName} ` : ""}
                <br />
                {accountInfo?.fatherName ? `${accountInfo.fatherName} ` : ""}
              </p>
            ) : (
              <>{t("labels.notIdent")}</>
            )}
            <p style={{ margin: "5px 0" }}>{accountInfo?.phone}</p>
          </div>

          <CSwitch
            checked={themeStore.isDarkTheme}
            onChange={themeStore.toggleTheme}
            startIcon={<GoSun />}
            endIcon={<IoMoonOutline />}
          />
        </div>
      </div>

      <div className="profile__navigations">
        <CDevider />

        <Link to={"/profile/settings"} className="profile__navigation">
          <div>
            <CiSettings />
            {t("profile.settings")}
          </div>
          <IoIosArrowForward />
        </Link>

        <CDevider />

        <Link to={"/profile/ident"} className="profile__navigation">
          <div>
            <FaRegUser />
            {t("profile.ident")}
          </div>
          <IoIosArrowForward />
        </Link>

        <CDevider />

        <Link to={"/profile/activeDevices"} className="profile__navigation">
          <div>
            <AiOutlineFileProtect />
            {t("profile.activeDevices")}
          </div>
          <IoIosArrowForward />
        </Link>

        <CDevider />

        <a
          href="https://www.google.com/maps/d/u/1/viewer?mid=1Qyf2v214sokPaCkfwX6qFiX3ZaVb8ec&ll=41.06337278524174%2C70.77036170000002&z=8"
          target="_blank"
          rel="noreferrer"
          className="profile__navigation"
        >
          <div>
            <GrMapLocation />
            {t("profile.terminals")}
          </div>

          <IoIosArrowForward />
        </a>

        <CDevider />
      </div>

      <CModal
        isOpen={isOpenModal}
        onToggle={toggleModal}
        content={
          <div className="profile__modal__content">
            <p className="profile__exit">{t("labels.exit")}</p>

            <CButton
              variant="success"
              title={t("button.yes")}
              onClick={logout}
            />
            <CButton onClick={toggleModal} title={t("button.cancel")} />
          </div>
        }
      />
    </div>
  );
});

export default Profile;
