import React, { useState } from "react";
import "./Register.css";
import pwLight from "../../../assets/images/pw-light.svg";
import pwDark from "../../../assets/images/pw-dark.svg";
import { observer } from "mobx-react-lite";
import CBack from "../../../components/CBack";
import { themeStore } from "../../../stores/themeStore";
import CButton from "../../../components/CButton";
import { useTranslation } from "react-i18next";
import CInput from "../../../components/CInput";
import { authStore } from "../../../stores/authStore";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

interface FormData {
  password: string;
  repeatPassword: string;
}

const Register: React.FC = observer(() => {
  const [message, setMessage] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const password = watch("password");

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (data.password === data.repeatPassword) {
      try {
        setIsLoading(true);

        const response = await authStore.register(data.password);

        if (response.message) {
          setMessage(response.message);
          return;
        }

        if (response.result) {
          navigate("/auth/register/confirm");
        }
      } catch (error) {
        console.error("Error during register:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="register wrapper">
      <div style={{ width: "100%" }}>
        <CBack />
      </div>

      <img
        src={themeStore.isDarkTheme ? pwDark : pwLight}
        alt="pw"
        className="auth__logo"
      />

      <form className="register__form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div>
            <p className="register__form__label">{t("createPassword.title")}</p>
            <CInput
              type="password"
              placeholder={t("createPassword.placeholder")}
              {...register("password", {
                required: t("error.passwordRequired"),
                minLength: {
                  value: 6,
                  message: t("error.passwordMinLength"),
                },
              })}
            />

            {errors.password && (
              <span className="danger__text register__error">
                {errors.password.message}
              </span>
            )}

            {message && <p className="error__message">{message}</p>}
          </div>

          <div>
            <p className="register__form__label">{t("againPassword.title")}</p>
            <CInput
              type="password"
              placeholder={t("againPassword.placeholder")}
              {...register("repeatPassword", {
                required: t("error.passwordRequired"),
                minLength: {
                  value: 6,
                  message: t("error.passwordMinLength"),
                },
                validate: (value) =>
                  value === password || t("error.passwordsNotMatch"),
              })}
            />
            {errors.repeatPassword && (
              <span className="danger__text register__error">
                {errors.repeatPassword.message}
              </span>
            )}
          </div>
        </div>

        <CButton
          title={t("labels.enter")}
          type="submit"
          isLoading={isLoading}
        />
      </form>
    </div>
  );
});

export default Register;
