import React from "react";
import { Navigate } from "react-router-dom";
import { TG_USER_ID } from "../constants/constant";

interface ProtectedRouteProps {
  element: React.ReactElement;
  redirectTo: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  redirectTo,
}) => {
  const isAuthenticated = !!localStorage.getItem(`token-${TG_USER_ID}`);
  const pincode = !!sessionStorage.getItem(`pincode-${TG_USER_ID}`);

  if (isAuthenticated && !pincode) {
    return <Navigate to="/pincode" />;
  }

  return isAuthenticated ? element : <Navigate to={redirectTo} />;
};

export default ProtectedRoute;
