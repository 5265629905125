import React from "react";
import "./CModal.css";
import { CModalProps } from "./CModal.types";

const CModal: React.FC<CModalProps> = ({ isOpen, onToggle, content }) => {
  if (!isOpen) return null;

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget && onToggle) {
      onToggle();
    }
  };

  return (
    <div className="cmodal wrapper" onClick={handleOutsideClick}>
      <div className="cmodal__content">
        <div className="cmodal__header">
          <span className="cmodal__header__bar"></span>
        </div>
        <div>{content}</div>
      </div>
    </div>
  );
};

export default CModal;
