import { makeAutoObservable } from "mobx";
import { STATIC_URL } from "../constants/constant";
import { IPaymentServiceCategory } from "../models/IPaymentServiceCategory";
import apiClient from "../services/apiClient";

class PaymentServiceStore {
  paymentServicesCache = null;
  paymentServiceCategoriesCache = null;
  paymentServicePopularCache = null;
  paymentServicesByCategoryCache = new Map<string, any>();

  constructor() {
    makeAutoObservable(this);
  }

  getPaymentServices = async () => {
    if (this.paymentServicesCache) {
      return this.paymentServicesCache;
    }

    try {
      const response = await apiClient.get("/paymentServices");
      this.paymentServicesCache = response.data;
      return response.data;
    } catch (error) {
      console.log("Error in getPaymentServices", error);
    }
  };

  getPaymentServiceByCategory = async (categoryId: string) => {
    if (this.paymentServicesByCategoryCache.has(categoryId)) {
      return this.paymentServicesByCategoryCache.get(categoryId);
    }

    try {
      const response = await apiClient.get(
        `/paymentServices/byCategory/${categoryId}`
      );

      this.paymentServicesByCategoryCache.set(categoryId, response.data);
      return response.data;
    } catch (error) {
      console.log("Error in getPaymentServiceByCategory", error);
    }
  };

  getPaymentServiceCategories = async () => {
    if (this.paymentServiceCategoriesCache) {
      return this.paymentServiceCategoriesCache;
    }

    try {
      const response = await apiClient.get("/paymentServices/categories");

      const updatedCategories = response.data.map(
        (item: IPaymentServiceCategory) => ({
          ...item,
          img: `${STATIC_URL}/img/servcats/${item.img}`,
          img_white: `${STATIC_URL}/img/servcats_white/${item.img}`,
          img_green: `${STATIC_URL}/img/servcats_green/${item.img}`,
        })
      );

      this.paymentServiceCategoriesCache = updatedCategories;
      return updatedCategories;
    } catch (error) {
      console.log("Error in getPaymentServiceCategories", error);
    }
  };

  getPaymentServicePopular = async () => {
    if (this.paymentServicePopularCache) {
      return this.paymentServicePopularCache;
    }

    try {
      const response = await apiClient.get("/paymentServices/popular");
      this.paymentServicePopularCache = response.data;
      return response.data;
    } catch (error) {
      console.log("Error in getPaymentServicePopular", error);
    }
  };

  getPaymentFrequentData = async (id?: number) => {
    try {
      const response = await apiClient.get(
        `/paymentServices/${id}/frequentData`
      );

      return response.data;
    } catch (error) {
      console.log("Error in getPaymentFrequentData", error);
    }
  };

  clearCaches = () => {
    this.paymentServicesCache = null;
    this.paymentServiceCategoriesCache = null;
    this.paymentServicePopularCache = null;
    this.paymentServicesByCategoryCache.clear();
  };
}

export const paymentServiceStore = new PaymentServiceStore();
