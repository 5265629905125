import React from "react";
import "./HistoryCard.css";
import { IHistoryCardProps } from "./HistoryCard.types";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { amountAddSpace, formateDateWithMonth } from "../../../utils/utils";
import { STATIC_URL } from "../../../constants/constant";
import HistoryCardStatus from "./HistoryCardStatus";

const HistoryCard: React.FC<IHistoryCardProps> = ({ history }) => {
  const dateItem = moment(history.trnDate).format("DD-MM-YYYY");
  const today = moment().format("DD-MM-YYYY");
  const yesterday = moment().add(-1, "day").format("DD-MM-YYYY");
  let dateForData = dateItem;
  const { t } = useTranslation();

  const dateFoRender = formateDateWithMonth(
    history.trnDate || ""
  ).toLowerCase();

  if (dateForData === today) {
    dateForData = `${t("labels.today")} ${dateFoRender}`;
  } else if (dateForData === yesterday) {
    dateForData = `${t("labels.yesterday")} ${dateFoRender}`;
  } else {
    dateForData = dateFoRender;
  }

  let img = `${STATIC_URL}/img/services/${
    history?.payment?.provider_id || 495
  }`;

  if (history.type !== "payment") {
    img = `${STATIC_URL}/img/paysys/${history?.paySystemId || 1}`;
  }

  const imgUrl =
    history.type === "p2p"
      ? `${STATIC_URL}/img/servcats/card.png`
      : `${img}/100x100.webp`;

  const amount = React.useMemo(() => {
    const amountCommission = Number(history?.amountCommission).toFixed(2);

    let amount = amountAddSpace(Number(amountCommission))

    return history?.refunded || false
      ? `${amount} ${history?.currencySign || t("balance.sum")}`
      : !history?.isCredit
      ? `-${amount} ${history?.currencySign || t("balance.sum")}`
      : `+${amount} ${history?.currencySign || t("balance.sum")}`;
  }, [
    history.amountCommission,
    history.currencySign,
    history.isCredit,
    history.refunded,
    t,
  ]);

  return (
    <Link
      to={`/history/${history.id}`}
      state={{ history: JSON.stringify(history) }}
      className="historyCard"
    >
      <div className="historyCard__details">
        <img
          src={imgUrl}
          alt={history?.receiverName}
          className="historyCard__img"
        />

        <div className="historyCard__info">
          <p className="historyCard__transaction">{history?.receiverName}</p>
          <p className="historyCard__note">{history.cardFrom}</p>
          <p className="historyCard__time">{dateFoRender}</p>
        </div>
      </div>

      <div className="historyCard__summary">
        <p
          className={`historyCard__amount ${
            history.isCredit
              ? "historyCard__amount-profit"
              : "historyCard__amount-expense"
          }`}
        >
          {amount}
        </p>

        <HistoryCardStatus status={history.status} />
      </div>
    </Link>
  );
};

export default HistoryCard;
