import React, { useState } from "react";
import "./ExpenseOrProfit.css";
import { useTranslation } from "react-i18next";

const ExpenseOrProfit: React.FC = () => {
  const { t } = useTranslation();
  const [activeBtn, setActiveBtn] = useState("");

  const handleButtonClick = (btnType: string) => {
    setActiveBtn((prevActiveBtn) => (prevActiveBtn === btnType ? "" : btnType));
  };

  return (
    <div className="expenseorprofit">
      <button
        className={`expenseorprofit__btn ${
          activeBtn === "profit" ? "expenseorprofit__btn--active" : ""
        }`}
        onClick={() => handleButtonClick("profit")}
      >
        {t("history.profit")}
      </button>
      <button
        className={`expenseorprofit__btn ${
          activeBtn === "expense" ? "expenseorprofit__btn--active" : ""
        }`}
        onClick={() => handleButtonClick("expense")}
      >
        {t("history.expense")}
      </button>
    </div>
  );
};

export default ExpenseOrProfit;
