import React, { useEffect, useState } from "react";
import "./Friend.css";
import SectionHeader from "../../../layouts/SectionHeader";
import { useTranslation } from "react-i18next";
import { pointStore } from "../../../stores/pointStore";
import { FaRegCopy } from "react-icons/fa";
import { amountAddSpace } from "../../../utils/utils";

interface IFriend {
  quantity: number;
  invitedPoints: number;
}

const Friend: React.FC = () => {
  const [friends, setFriends] = useState<IFriend>();
  const [inviteLink, setInviteLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();

  const fetchFriends = async () => {
    try {
      const response = await pointStore.friends();

      setFriends(response);
    } catch (error) {
      console.log("Error fetching friends", error);
    }
  };

  const getInviteLink = async () => {
    try {
      const response = await pointStore.getInvite();

      setInviteLink(response.result);
    } catch (error) {
      console.log("Error getting invite link", error);
    }
  };

  const copyInviteLink = () => {
    navigator.clipboard.writeText(inviteLink);

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  useEffect(() => {
    fetchFriends();
    getInviteLink();
  });

  return (
    <>
      <SectionHeader />

      <div className="friend wrapper">
        <div>
          <h3 className="friend__title">{t("point.friendTitle")}</h3>
          <p className="friend__desc">{t("point.friendDesc")}</p>
        </div>

        <div>
          <div className="friend__img__wrapper">
            <div className="friend__img">
              <p className="friend__count">+{friends?.quantity}</p>
            </div>
          </div>

          <div className="friend__balance">
            <h3 className="friend__balance__title">{t("point.youEarned")}</h3>
            <h2 className="friend__balance__point">
              + {amountAddSpace(Number(friends?.invitedPoints) || 0)}
            </h2>
          </div>

          <div>
            <p className="friend__invite__title">{t("point.yourLink")}</p>
            <div className="friend__invite">
              <p>{inviteLink}</p>

              <FaRegCopy
                className="friend__invite__copy"
                onClick={copyInviteLink}
              />
            </div>

            {isCopied && (
              <p className="friend__invite__copied">{t("point.copied")}</p>
            )}
          </div>
        </div>

        <div>
          <p className="friend__help">{t("point.help")}</p>
        </div>
      </div>
    </>
  );
};

export default Friend;
