import React, { useState } from "react";
import "./Settings.css";
import SectionHeader from "../../layouts/SectionHeader";
import { useTranslation } from "react-i18next";
import CButton from "../../components/CButton";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoNewspaperOutline } from "react-icons/io5";
import { MdLanguage } from "react-icons/md";
import CDevider from "../../components/CDevider";
import CModal from "../../components/CModal";
import { GrDocumentText } from "react-icons/gr";

const Settings: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { t } = useTranslation();

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <>
      <SectionHeader title={t("profile.settings")} backTo="/profile" />

      <div className="settings wrapper">
        <div className="settings__contents">
          <CDevider />

          <a
            href="https://1pay.uz/privacy-policy/"
            target="_blank"
            rel="noreferrer"
            className="settings__content"
          >
            <div>
              <IoNewspaperOutline />

              {t("profile.termsAndConditions")}
            </div>

            <IoIosArrowForward />
          </a>

          <CDevider />

          <a
            href="https://static.1pay.uz/offert_promo.pdf"
            target="_blank"
            rel="noreferrer"
            className="settings__content"
          >
            <div>
              <GrDocumentText />

              {t("profile.offer")}
            </div>

            <IoIosArrowForward />
          </a>

          <CDevider />

          <Link to={"/profile/settings/language"} className="settings__content">
            <div>
              <MdLanguage />

              {t("profile.changeLang")}
            </div>

            <IoIosArrowForward />
          </Link>
        </div>

        <CButton
          variant="danger"
          title={t("labels.deleteProfile")}
          onClick={toggleModal}
        />
      </div>

      <CModal
        isOpen={isOpenModal}
        onToggle={toggleModal}
        content={
          <div className="settings__modal__content">
            <p className="settings__exit">{t("profile.titleDeleteAccount")}</p>

            <CButton
              variant="success"
              onClick={() => console.log("Delete")}
              title={t("button.yes")}
            />
            <CButton
              variant="primary"
              onClick={toggleModal}
              title={t("button.cancel")}
            />
          </div>
        }
      />
    </>
  );
};

export default Settings;
