import { makeAutoObservable } from "mobx";
import apiClient from "../services/apiClient";

class HistoryStore {
  historyCache = null;

  constructor() {
    makeAutoObservable(this);
  }

  getHistory = async () => {
    if (this.historyCache) {
      return this.historyCache;
    }

    try {
      const response = await apiClient.get("/history");

      this.historyCache = response.data;

      return response.data;
    } catch (error) {
      console.log("Error in getHistory", error);
    }
  };

  reGetHistory = async () => {
    this.historyCache = null;
    
    return this.getHistory();
  }
}

export const historyStore = new HistoryStore();
