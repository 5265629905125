import React from "react";
import "./Deposit.css";
import SectionHeader from "../../../layouts/SectionHeader";
import { useTranslation } from "react-i18next";

const Deposit: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <SectionHeader />

      <div className="deposit wrapper">
        <div>
          <h3 className="deposit__title">{t("point.soon")}!</h3>
          <p className="deposit__desc">{t("point.depositDesc")}</p>
        </div>

        <div className="deposit__img__wrapper">
          <div className="deposit__img">
            <p className="deposit__count">
              11% <br />
              <span> {t("point.annual")}</span>
            </p>
          </div>
        </div>

        <div>
          <p className="deposit__help">{t("point.help")}</p>
        </div>
      </div>
    </>
  );
};

export default Deposit;
