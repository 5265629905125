import React, { useEffect, useState } from "react";
import "./Payment.css";
import SectionHeader from "../../layouts/SectionHeader";
import { useTranslation } from "react-i18next";
import { paymentServiceStore } from "../../stores/paymentServiceStore";
import CCardSkeleton from "../../components/CCardSkeleton";
import { IPaymentService } from "../../models/PaymentService";
import { STATIC_URL } from "../../constants/constant";
import { Link } from "react-router-dom";

const PopularServices: React.FC = () => {
  const [popularServices, setPopularServices] = useState<IPaymentService[]>([]);
  const [isLoadingPopularService, setIsLoadingPopularService] = useState(false);
  const { t } = useTranslation();
  const skeleton = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const fetchPopularServices = async () => {
    try {
      setIsLoadingPopularService(true);
      const response = await paymentServiceStore.getPaymentServicePopular();

      setPopularServices(response);
    } catch (error) {
      console.log("Error in getPaymentPopular", error);
    } finally {
      setIsLoadingPopularService(false);
    }
  };

  useEffect(() => {
    fetchPopularServices();
  }, []);

  return (
    <>
      <SectionHeader title={t("labels.popular_services")} />

      <div className="popularService wrapper">
        <div className="popularService__container">
          {isLoadingPopularService &&
            skeleton.map((item) => <CCardSkeleton key={item} />)}

          {popularServices?.map((popularService) => (
            <Link
              to={`/payment/service/${popularService.id}`}
              state={{ service: JSON.stringify(popularService) }}
              key={popularService.id}
              className="payment__service__item"
            >
              <img
                src={`${STATIC_URL}//img/services/${popularService.id}/100x100.webp`}
                alt={popularService.name}
                className="payment__service__item__img"
              />
              <p className="payment__service__item__name">
                {popularService.name}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default PopularServices;
