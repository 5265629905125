import { makeAutoObservable } from "mobx";
import apiClient from "../services/apiClient";

class FavoriteStore {
  private favoritesListCache = null;
  private favoriteGroupsCache = null;

  constructor() {
    makeAutoObservable(this);
  }

  favoritesList = async () => {
    if (this.favoritesListCache) {
      return this.favoritesListCache;
    }

    try {
      const response = await apiClient.get("/favorites");

      this.favoritesListCache = response.data;

      return response.data;
    } catch (error) {
      console.error("Error in favoritesList", error);
    }
  };

  addFavorite = async (
    account?: string,
    amount?: number,
    providerId?: number,
    autopay?: boolean,
    note?: string,
    fields?: {
      [key: string]: string;
    }
  ) => {
    try {
      const response = await apiClient.post("/favorites", {
        account,
        amount,
        providerId,
        autopay,
        note,
        fields,
      });

      this.favoritesListCache = null;

      return response.data;
    } catch (error) {
      console.log("Error in addFavorite", error);
    }
  };

  editFavorite = async (
    id?: number,
    amount?: number,
    groupId?: number,
    account?: string,
    note?: string,
    fields?: {
      [key: string]: string;
    }
  ) => {
    try {
      const response = await apiClient.put("/favorites", {
        id,
        amount,
        groupId,
        account,
        note,
        fields,
      });

      this.favoritesListCache = null;

      return response.data;
    } catch (error) {
      console.error("Error in editFavorite", error);
    }
  };

  deleteFavorite = async (id: number) => {
    try {
      const response = await apiClient.delete(`/favorites/${id}`);

      this.favoritesListCache = null;

      return response.data;
    } catch (error) {
      console.error("Error in deleteFavorite", error);
    }
  };

  favoriteGroups = async () => {
    if (this.favoriteGroupsCache) {
      return this.favoriteGroupsCache;
    }

    try {
      const response = await apiClient.get("/favorites/groups");

      this.favoriteGroupsCache = response.data;

      return response.data;
    } catch (error) {
      console.error("Error in favoriteGroups", error);
    }
  };

  addGroup = async (text: string) => {
    try {
      const response = await apiClient.post("/favorites/groups", { text });

      this.favoriteGroupsCache = null;

      return response.data;
    } catch (error) {
      console.log("Error in adding group", error);
    }
  };

  editGroup = async (id: number, note: string) => {
    try {
      const response = await apiClient.put(`/favorites/groups`, { id, note });

      this.favoriteGroupsCache = null;

      return response.data;
    } catch (error) {
      console.log("Error in editing group", error);
    }
  };

  deleteGroup = async (id: number, clear: boolean) => {
    try {
      const response = await apiClient.delete(`/favorites/groups/${id}`, {
        params: { clear },
      });

      this.favoriteGroupsCache = null;

      return response.data;
    } catch (error) {
      console.log("Error in deleting group", error);
    }
  };

  editFavoriteGroup = async (id: number, groupId: number) => {
    try {
      const response = await apiClient.put(`/favorites`, { id, groupId });

      this.favoritesListCache = null;

      return response.data;
    } catch (error) {
      console.log("Error in editing favorite group", error);
    }
  };
}

export const favoriteStore = new FavoriteStore();
