import { makeAutoObservable } from "mobx";

class StoryStore {
  hasSeenStory = false;

  constructor() {
    makeAutoObservable(this);
  }

  setHasSeenStory = (value: boolean) => {
    this.hasSeenStory = value;
  };
}

export const storyStore = new StoryStore();
