import React, { useEffect, useState, useCallback } from "react";
import "./CPaymentCategoryWidget.css";
import { ICPaymentCategoryWidgetProps } from "./CPaymentCategoryWidget.types";
import { paymentServiceStore } from "../../../stores/paymentServiceStore";
import { IPaymentService } from "../../../models/PaymentService";
import { STATIC_URL } from "../../../constants/constant";
import { Link } from "react-router-dom";
import CCardSkeleton from "../../../components/CCardSkeleton";

const CPaymentCategoryWidget: React.FC<ICPaymentCategoryWidgetProps> = ({
  categoryId,
  title,
}) => {
  const [services, setServices] = useState<IPaymentService[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchServices = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await paymentServiceStore.getPaymentServiceByCategory(
        categoryId
      );

      setServices(response.slice(0, 6));
    } catch (error) {
      console.log("Error in fetchServices in Destination", error);
    } finally {
      setIsLoading(false);
    }
  }, [categoryId]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  return (
    <Link
      to={`/payment/destination/${categoryId}`}
      className="cpaymentcategorywidget"
    >
      <h2 className="cpaymentcategorywidget__title">{title}</h2>

      <div className="cpaymentcategorywidget__container">
        {isLoading
          ? Array.from({ length: 6 }).map((_, index) => (
              <CCardSkeleton key={index} size="small" />
            ))
          : services?.map((service) => (
              <div key={service.id} className="cpaymentcategorywidget__item">
                <img
                  src={`${STATIC_URL}/img/services/${service.id}/100x100.webp`}
                  alt={service.name}
                  className="cpaymentcategorywidget__item__img"
                />
              </div>
            ))}
      </div>
    </Link>
  );
};

export default CPaymentCategoryWidget;
