import { useTranslation } from "react-i18next";

export const useTypeDocOptions = () => {
  const { t } = useTranslation();

  const typeDocOptions = [
    { id: 1, title: t("labels.passport"), value: "3" },
    { id: 2, title: t("labels.idCard"), value: "4" },
  ];

  return typeDocOptions;
};
