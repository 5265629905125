import React, { useEffect, useState } from "react";
import "./AllServices.css";
import CInput from "../../../../components/CInput";
import { paymentServiceStore } from "../../../../stores/paymentServiceStore";
import { IPaymentService } from "../../../../models/PaymentService";
import { STATIC_URL } from "../../../../constants/constant";
import { Link } from "react-router-dom";

const AllServices: React.FC = () => {
  const [search, setSearch] = useState("");
  const [services, setServices] = useState<IPaymentService[]>([]);

  const fetchServices = async () => {
    try {
      const response = await paymentServiceStore.getPaymentServices();

      setServices(response);
    } catch (error) {
      console.log("Error in fetchServices", error);
    }
  };

  const filteredServices = services?.filter((service: IPaymentService) =>
    service.name?.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <div className="allservices">
      <CInput
        placeholder="Введите название услуги"
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
      />

      <div className="allservices__list">
        {filteredServices?.map((service: IPaymentService) => (
          <Link
            to={`/payment/housefavorite/addFavoriteService/${service?.id}`}
            key={service.id}
            className="allservices__item"
            state={{ service: JSON.stringify(service) }}
          >
            <img
              src={`${STATIC_URL}/img/services/${service?.id}/100x100.webp`}
              alt={service?.name}
              className="allservices__item__img"
            />
            <p className="allservices__item__name">{service?.name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AllServices;
