import React, { useRef } from "react";
import "./CCardSwiper.css";
import { CCardSwiperProps } from "./CCardSwiper.types";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import CCard from "../CCard/CCard";
import { GrNext, GrPrevious } from "react-icons/gr";

const CCardSwiper: React.FC<CCardSwiperProps> = ({
  cards,
  setSelectedCard,
  isLoading,
}) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const handleSlideChange = (swiper: any) => {
    if (setSelectedCard && cards) {
      setSelectedCard(cards[swiper.activeIndex]);
    }
  };

  return (
    <div className="ccardswiper">
      <span ref={prevRef} className="ccardswiper__btn">
        <GrPrevious />
      </span>

      <Swiper
        onSlideChange={handleSlideChange}
        grabCursor={true}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        modules={[Navigation]}
      >
        {isLoading && (
          <SwiperSlide className="card__loading ccardswiper__slide"></SwiperSlide>
        )}

        {cards?.map((card) => {
          return (
            <SwiperSlide key={card.id} className="ccardswiper__slide">
              <CCard card={card} />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <span ref={nextRef} className="ccardswiper__btn">
        <GrNext />
      </span>
    </div>
  );
};

export default CCardSwiper;
