import React from "react";
import "./Story.css";
import Stories from "stories-react";
import "stories-react/dist/index.css";
import { storyImages } from "../../db/storyImages";
import { storyStore } from "../../stores/storyStore";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

const Story: React.FC = observer(() => {
  const navigate = useNavigate();

  const handleStoryEnd = () => {
    localStorage.setItem("hasSeenStory", "true");
    storyStore.setHasSeenStory(true);

    navigate("/");
  };

  return (
    <div className="story">
      <Stories
        width="100%"
        height="100vh"
        stories={storyImages}
        onAllStoriesEnd={handleStoryEnd}
      />
    </div>
  );
});

export default Story;
