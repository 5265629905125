import React, { useEffect, useState } from "react";
import "./ServiceConfirmSms.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SectionHeader from "../../../../layouts/SectionHeader";
import { themeStore } from "../../../../stores/themeStore";
import CInput from "../../../../components/CInput";
import CButton from "../../../../components/CButton";
import pwDark from "../../../../assets/images/pw-dark.svg";
import pwLight from "../../../../assets/images/pw-light.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { payStore } from "../../../../stores/payStore";

interface IFormData {
  code: string;
}

const ServiceConfirmSms: React.FC = () => {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(60);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const parsedState = JSON.parse(location.state);
  const state = parsedState.response;
  const cardId = parsedState.cardId;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>();

  const onConfirm = async (data: IFormData) => {
    try {
      setIsLoading(true);

      const response = await payStore.confirm(
        Number(state.paymentId),
        Number(cardId),
        Number(state.opId),
        Number(data.code),
        Number(state.payinId)
      );

      if (response.message) {
        setMessage(response.message);
        return;
      }

      if (response) {
        navigate(`/payment/service/result/${state.id}`, {
          state: { response },
        });
      }
    } catch (error) {
      console.log("Error in onConfirm", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  return (
    <>
      <SectionHeader title={t("labels.confirmSms")} />

      <div className="serviceConfirmSms wrapper">
        <div>
          <div className="serviceConfirmSms__pw__container">
            <img
              src={themeStore.isDarkTheme ? pwDark : pwLight}
              alt="pw"
              className="serviceConfirmSms__pw"
            />
          </div>

          <div className="serviceConfirmSms__content">
            <p className="serviceConfirmSms__text serviceConfirmSms__title">
              {t("code.title")}
            </p>

            <CInput
              maxLength={6}
              inputMode="numeric"
              placeholder={t("code.placeholder")}
              {...register("code", {
                required: t("error.codeRequired"),
                minLength: {
                  value: 6,
                  message: t("error.codeMinLength"),
                },
                maxLength: {
                  value: 6,
                  message: t("error.codeMaxLength"),
                },
              })}
            />

            {errors.code && (
              <p className="serviceConfirmSms__text danger__text">
                {errors.code.message}
              </p>
            )}

            <p className="serviceConfirmSms__timer">
              {timer} {t("labels.sec")}
            </p>

            {message && (
              <p className="serviceConfirmSms__text error__message">
                {message}
              </p>
            )}
          </div>
        </div>

        <CButton
          variant="success"
          title={t("button.confirm")}
          onClick={handleSubmit(onConfirm)}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default ServiceConfirmSms;
