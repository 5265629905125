import React, { useState, useRef, useEffect } from "react";
import "./IdentCreate.css";
import SectionHeader from "../../../layouts/SectionHeader";
import { useTranslation } from "react-i18next";
import CButton from "../../../components/CButton";
import CInput from "../../../components/CInput";
import { IoCameraOutline } from "react-icons/io5";
import CSelect from "../../../components/CSelect";
import { IServiceField } from "../../Payment/PaymentService/PaymentService.types";
import { useForm } from "react-hook-form";
import { useHookFormMask } from "use-mask-input";
import moment from "moment";
import { accountStore } from "../../../stores/accountStore";
import { Link } from "react-router-dom";
import { useTypeDocOptions } from "./useTypeDocOptions";
import { useTypeCountryResidenceOptions } from "./useTypeCountryResidenceOptions";
import CModal from "../../../components/CModal";
import { isMobileDevice } from "../../../utils/utils";

interface FormData {
  dateBirth: string;
  idCard: string;
}

const IdentCreate: React.FC = () => {
  const { t } = useTranslation();
  const typeDocOptions = useTypeDocOptions();
  const typeCountryResidenceOptions = useTypeCountryResidenceOptions();
  const [selectedTypeDoc, setSelectedTypeDoc] = useState<IServiceField>(
    typeDocOptions[0]
  );
  const [selectedCountryResidence, setSelectedCountryResidence] = useState(
    typeCountryResidenceOptions[0]
  );
  const [selfieFile, setSelfieFile] = useState<File>();
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageSelfie, setMessageSelfie] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [mainModal, setMainModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const registerWithMask = useHookFormMask(register);

  const onSubmit = async (data: FormData) => {
    if (!selfieFile) {
      setMessageSelfie(t("labels.required"));
      return;
    }

    const docNumber = data.idCard.replace(/\s|[A-z]/g, "");
    const docSer = data.idCard.replace(/\s|\d/g, "");
    const bdate = moment(data.dateBirth, "DD-MM-YYYY").format("YYYY-MM-DD");
    const emptyFile = new File([], "emptyfile.png", { type: "image/png" });

    try {
      setIsLoading(true);

      const response = await accountStore.identification(
        selectedCountryResidence.value,
        "",
        "",
        "",
        docSer,
        docNumber,
        "",
        "",
        "",
        "",
        "",
        "",
        bdate,
        selfieFile,
        emptyFile,
        emptyFile
      );

      if (response.message) {
        setMessage(response.message);

        return;
      }

      if (response) {
        setIsOpenModal(true);
      }
    } catch (error) {
      console.error("Error identification", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCameraOpen = async () => {
    setIsCameraOpen(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error("Error accessing the camera", error);
    }
  };

  const takePhoto = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;

        context.drawImage(videoRef.current, 0, 0);

        const photoDataUrl = canvasRef.current.toDataURL("image/png");

        fetch(photoDataUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "selfie.png", { type: "image/png" });
            setSelfieFile(file);
          });

        const stream = videoRef.current.srcObject as MediaStream;
        stream.getTracks().forEach((track) => track.stop());
        videoRef.current.srcObject = null;
        setIsCameraOpen(false);
      }
    }
  };

  useEffect(() => {
    if (!isMobileDevice()) {
      setMainModal(true);
    }
  }, []);

  return (
    <>
      <SectionHeader title={t("ident.completeIdent")} />

      <div className="identCreate wrapper">
        <div className="identCreate__content">
          <div className="identCreate__field">
            <p className="identCreate__label">{t("ident.countryResidence")}</p>
            <CSelect
              options={typeCountryResidenceOptions}
              placeholder={t("ident.countryResidencePlaceholder")}
              value={selectedCountryResidence || typeCountryResidenceOptions[0]}
              onChange={(value: any) => setSelectedCountryResidence(value)}
            />
          </div>

          <div className="identCreate__field">
            <p className="identCreate__label">{t("ident.typeDocument")}</p>
            <CSelect
              options={typeDocOptions}
              placeholder={t("ident.typeDocumentPlaceholder")}
              onChange={(value: IServiceField) => setSelectedTypeDoc(value)}
              value={selectedTypeDoc || typeDocOptions[0]}
            />
          </div>

          <div className="identCreate__field">
            <p className="identCreate__label">{t("ident.dateBirth")}</p>
            <CInput
              placeholder={t("ident.DDMMYYYY")}
              inputMode="numeric"
              {...registerWithMask("dateBirth", ["99-99-9999"], {
                required: t("labels.required"),
              })}
            />
            {errors.dateBirth && (
              <p className="error__message">{errors.dateBirth.message}</p>
            )}
          </div>

          <div className="identCreate__field">
            <p className="identCreate__label">{t("ident.idCard")}</p>
            <CInput
              placeholder={t("ident.idCardPlaceholder")}
              {...registerWithMask("idCard", ["AA 9999999"], {
                required: t("labels.required"),
              })}
            />
            {errors.idCard && (
              <p className="error__message">{errors.idCard.message}</p>
            )}
          </div>

          <div className="identCreate__field">
            <p className="identCreate__label">
              {t("ident.selfiWithoutPassword")}
            </p>
            <IoCameraOutline
              className="identCreate__camera"
              onClick={handleCameraOpen}
            />
            {messageSelfie && <p className="error__message">{messageSelfie}</p>}
            {!selfieFile && (
              <p className="error__message">
                {t("ident.selfiWithoutPasswordPlaceholder")}
              </p>
            )}

            <canvas
              ref={canvasRef}
              className="identCreate__camera__canvas"
            ></canvas>
          </div>
        </div>

        {message && <p className="error__message">{message}</p>}

        <CButton
          title={t("button.send")}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </div>

      {isCameraOpen && (
        <div className="identCreate__camera__modal">
          <video
            ref={videoRef}
            playsInline
            className="identCreate__camera__video"
          />
          <IoCameraOutline
            className="identCreate__camera identCreate__camera-icon"
            onClick={takePhoto}
          />
        </div>
      )}

      <CModal
        isOpen={isOpenModal}
        content={
          <div>
            <p className="identCreate__modal__title">{t("ident.done")}</p>

            <Link to={"/profile/ident"} className="identCreate__modal__btn">
              <CButton title={t("button.ok")} />
            </Link>
          </div>
        }
      />

      <CModal
        isOpen={mainModal}
        content={
          <div>
            <p className="identCreate__modal__title">
              {t("ident.completeUsingMobile")}
            </p>

            <Link to={"/profile/ident"} className="identCreate__modal__btn">
              <CButton title={t("button.ok")} />
            </Link>
          </div>
        }
      />
    </>
  );
};

export default IdentCreate;
