import React, { useEffect, useState } from "react";
import "./Point.css";
import friend from "../../assets/images/friend.svg";
import deposit from "../../assets/images/deposit.svg";
import point from "../../assets/images/point.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { pointStore } from "../../stores/pointStore";
import { amountAddSpace } from "../../utils/utils";
import { themeStore } from "../../stores/themeStore";
import { STATIC_URL } from "../../constants/constant";
import { MdOutlineHistory } from "react-icons/md";

interface IPointAmount {
  earnedAmount?: number;
  receivedAmount?: number;
  startDate?: string;
  endDate?: string;
}

interface ITask {
  id: number;
  name: string;
  description: string;
}

const Point: React.FC = () => {
  const [pointAmount, setPointAmount] = useState<IPointAmount>();
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [isLoadingTasks, setIsLoadingTasks] = useState(false);
  const { t } = useTranslation();

  const getPointAmount = async () => {
    try {
      const response = await pointStore.getAmount();

      setPointAmount(response);
    } catch (error) {
      console.log("Error in getPointAmount", error);
    }
  };

  const getTasks = async () => {
    try {
      setIsLoadingTasks(true);
      const response = await pointStore.tasks();

      setTasks(response);
    } catch (error) {
      console.log("Error in getTasks", error);
    } finally {
      setIsLoadingTasks(false);
    }
  };

  useEffect(() => {
    getPointAmount();
    getTasks();
  }, []);

  return (
    <div className="point wrapper">
      <div className="point__card__wrapper">
        <div className="point__card">
          <p className="point__card__title">{t("point.yourBalance")}</p>
          <p className="point__card__point">
            {amountAddSpace(pointAmount?.earnedAmount || 0)} {t("point.point")}
          </p>
        </div>
      </div>

      <div className="point__actions">
        <Link to={"/point/friend"} className="point__action">
          <img src={friend} alt="friend" />
          <p>{t("point.friends")}</p>
        </Link>

        <Link to={"/point/exchange"} className="point__action">
          <img src={point} alt="point" />
          <p>{t("point.exchangePoint")}</p>
        </Link>

        <Link to={"/point/deposit"} className="point__action">
          <img src={deposit} alt="deposit" />
          <p>{t("point.depositPoint")}</p>
        </Link>

        <Link to={"/point/history"} className="point__action">
          <MdOutlineHistory className="point__history__icon"/>
          <p>{t("point.history")}</p>
        </Link>
      </div>

      <div className="point__tasks">
        <div>
          <h2 className="point__tasks__title">{t("point.tasks")}</h2>
          <p className="point__tasks__desc">{t("point.tasksDesc")}</p>
        </div>

        <div className="point__tasks__list">
          {isLoadingTasks && (
            <>
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <div
                    key={index}
                    className="point__tasks__item point__tasks__item-skeleton"
                  ></div>
                ))}
            </>
          )}

          {tasks?.map((task) => (
            <div key={task.id} className="point__tasks__item">
              <div className="point__tasks__item__content">
                <img
                  src={`${STATIC_URL}/img/tgachieves/${task.id}.svg`}
                  alt="taskIcon"
                  className={`${
                    themeStore.isDarkTheme
                      ? ""
                      : "point__tasks__item__icon-light"
                  } point__tasks__item__icon`}
                />
                <p className="point__tasks__item__title">{task.name}</p>
              </div>
              <p className="point__tasks__item__desc">{task.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Point;
