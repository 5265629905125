import React, { useState } from "react";
import "./Auth.css";
import pwLight from "../../assets/images/pw-light.svg";
import pwDark from "../../assets/images/pw-dark.svg";
import { themeStore } from "../../stores/themeStore";
import { observer } from "mobx-react-lite";
import CButton from "../../components/CButton";
import { useTranslation } from "react-i18next";
import CBack from "../../components/CBack";
import { IRegisterCheckResponse, authStore } from "../../stores/authStore";
import { useNavigate } from "react-router-dom";

const Auth: React.FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const requestPhoneNumber = async () => {
    // const response: IRegisterCheckResponse = await authStore.registerCheck(
    //   "998774778474"
    // );

    // if (response.result === true) {
    //   navigate("/auth/login");
    // }

    // if (response.result === false) {
    //   navigate("/auth/register");
    // }

    try {
      setIsLoading(true);

      window.Telegram.WebApp.requestContact((contactResponse) => {
        if (contactResponse) {
          window.Telegram.WebApp.onEvent(
            "contactRequested",
            async (event: any) => {
              try {
                let phoneNumber = event.responseUnsafe.contact.phone_number;

                if (phoneNumber.startsWith("+")) {
                  phoneNumber = phoneNumber.substring(1);
                }

                const response: IRegisterCheckResponse =
                  await authStore.registerCheck(phoneNumber);

                if (response.result) {
                  navigate("/auth/login");
                }

                if (response.result === false) {
                  navigate("/auth/offer");
                }
              } catch (error) {
                console.error("Error during registerCheck:", error);
                navigate("/auth");
              }
            }
          );
        }
      });
    } catch (error) {
      console.error("Error during requestPhoneNumber:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auth wrapper">
      <div style={{ width: "100%" }}>
        <CBack to="/" />
      </div>

      <div className="auth__content">
        <img
          src={themeStore.isDarkTheme ? pwDark : pwLight}
          alt="pw"
          className="auth__logo"
        />

        <CButton
          title={t("auth.sendNumber")}
          onClick={requestPhoneNumber}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
});

export default Auth;
