import React, { useState } from "react";
import "./AddCard.css";
import SectionHeader from "../../layouts/SectionHeader";
import { useTranslation } from "react-i18next";
import { themeStore } from "../../stores/themeStore";
import pwLight from "../../assets/images/pw-light.svg";
import pwDark from "../../assets/images/pw-dark.svg";
import CButton from "../../components/CButton";
import { useForm } from "react-hook-form";
import CInput from "../../components/CInput";
import { cardStore } from "../../stores/cardStore";
import { useNavigate } from "react-router-dom";

interface IFormData {
  code: string;
}

const AddCardConfirm: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>();
  const navigate = useNavigate();

  const onConfirm = async (data: IFormData) => {
    try {
      setIsLoading(true);
      const response = await cardStore.addCardConfirm(data.code);

      setErrorMessage(response.message);

      if (response.result) {
        await cardStore.reGetList();

        navigate("/payment");
      }
    } catch (error) {
      console.log("Error in addCardConfirm", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SectionHeader title={t("labels.confirmSms")} />

      <div className="addcard addcard-confirm wrapper">
        <div>
          <div className="addcard__pw__container">
            <img
              src={themeStore.isDarkTheme ? pwDark : pwLight}
              alt="pw"
              className="addcard__pw"
            />
          </div>

          <p className="addcard__text addcard__text-confirm">
            {t("code.title")}
          </p>

          <CInput
            placeholder={t("code.placeholder")}
            maxLength={6}
            inputMode="numeric"
            {...register("code", {
              required: t("error.codeRequired"),
              minLength: {
                value: 6,
                message: t("error.codeMinLength"),
              },
              maxLength: {
                value: 6,
                message: t("error.codeMaxLength"),
              },
            })}
          />

          {errors.code && (
            <span className="danger__text login__error">
              {errors.code.message}
            </span>
          )}

          {errorMessage && <p className="error__message">{errorMessage}</p>}
        </div>

        <CButton
          title={t("labels.confirm")}
          variant="success"
          onClick={handleSubmit(onConfirm)}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default AddCardConfirm;
