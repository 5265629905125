import React from "react";
import CDevider from "../../../components/CDevider";

interface DataFieldProps {
  label: string;
  value: string | undefined;
}

const DataField: React.FC<DataFieldProps> = ({ label, value }) => (
  <>
    <CDevider />
    <div className="identData__content">
      <p className="identData__label">{label}</p>
      <p className="identData__value">{value || "-"}</p>
    </div>
  </>
);

export default DataField;
