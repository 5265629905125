import React, { useEffect, useState } from "react";
import "./HotNews.css";
import { hotNewsStore } from "../../../stores/hotNewsStore";
import CCardSkeleton from "../../../components/CCardSkeleton";
import { IHotNew } from "./IHotNew";
import { Link } from "react-router-dom";
import { themeStore } from "../../../stores/themeStore";

const HotNews: React.FC = () => {
  const [hotNews, setHotNews] = useState<IHotNew[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const skeleton = [1, 2, 3];

  useEffect(() => {
    const fetchHotNews = async () => {
      try {
        setIsLoading(true);
        const response = await hotNewsStore.getHotNews();

        setHotNews(response);
      } catch (error) {
        console.error("Error fetching hot news", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHotNews();
  }, []);

  return (
    <div className="hotnews">
      {isLoading && skeleton.map((item) => <CCardSkeleton key={item} />)}

      {hotNews?.map((news) => (
        <Link to={`/payment/hotnews/${news.id}`} key={news.id} className="hotnews__item">
          <img
            src={themeStore.isDarkTheme ? news.smallPictureDark : news.smallPicture}
            alt={news.url}
            className="hotnews__item__image"
          />
        </Link>
      ))}
    </div>
  );
};

export default HotNews;
