import React, { useEffect, useState } from "react";
import "./ExchangeConfirm.css";
import SectionHeader from "../../../../layouts/SectionHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CDevider from "../../../../components/CDevider";
import { amountAddSpace } from "../../../../utils/utils";
import CButton from "../../../../components/CButton";
import { cardStore } from "../../../../stores/cardStore";
import { ICard } from "../../../Payment/Cards/ICard";

const ExchangeConfirm: React.FC = () => {
  const [tgCard, setTgCard] = useState<ICard>();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const handleConfirm = async () => {
    try {
      setIsLoading(true);

      const response = await cardStore.processCardTransaction(
        state.id,
        tgCard?.id
      );

      if (response.message) {
        setMessage(response.message);
        return;
      }

      if (response.needConfirm) {
        navigate(`/transfer/confirmSms/${state.id}`, {
          state: { response },
        });
      } else if (!response.needConfirm && !response.message) {
        navigate(`/transfer/result/${state.id}`, { state: { response } });
      }
    } catch (error) {
      console.log("Error in handleConfirm", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCardList = async () => {
    try {
      const response = await cardStore.getList();
      const card = response.find((card: ICard) => card.type === "19");

      setTgCard(card);
    } catch (error) {
      console.log("Error in getCardList", error);
    }
  };

  useEffect(() => {
    getCardList();
  }, []);
  return (
    <>
      <SectionHeader title={t("transaction.confirmTransaction")} />

      <div className="exchangeConfirm wrapper">
        <div className="exchangeConfirm__content">
          <div className="exchangeConfirm__info">
            <p className="exchangeConfirm__label">
              {t("aboutTransaction.ownerCard")}
            </p>
            <p className="exchangeConfirm__value">{state.receiver?.number}</p>
          </div>

          <CDevider />

          <div className="exchangeConfirm__info">
            <p className="exchangeConfirm__label">
              {t("aboutTransaction.amountPayment")}
            </p>
            <p className="exchangeConfirm__value">
              {amountAddSpace(Number(state.amount))} {t("balance.sum")}
            </p>
          </div>

          <div className="exchangeConfirm__info">
            <p className="exchangeConfirm__label">
              {t("aboutTransaction.amountDebit")}
            </p>
            <p className="exchangeConfirm__value">
              {amountAddSpace(Number(state.amountCommission))}{" "}
              {t("balance.sum")}
            </p>
          </div>
        </div>

        <div className="exchangeConfirm__wrapper">
          <p className="error__message">{message}</p>

          <div className="exchangeConfirm__actions">
            <Link to={"/point/exchange"} className="exchangeConfirm__link">
              <CButton title={t("button.goBack")} />
            </Link>

            <CButton
              title={t("button.confirm")}
              onClick={handleConfirm}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExchangeConfirm;
