import React, { useState } from "react";
import "./CSelect.css";
import { CSelectProps } from "./CSelect.types";
import CDevider from "../CDevider";
import { useTranslation } from "react-i18next";
import { IServiceFieldItem } from "../../pages/Payment/PaymentService/PaymentService.types";

const CSelect: React.FC<CSelectProps> = ({
  options = [],
  value,
  onChange,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleSelect = () => setIsOpen((prev) => !prev);

  const handleOptionClick = (option: IServiceFieldItem) => {
    onChange && onChange(option);
    setIsOpen(false);
  };

  const isSelected = (option: IServiceFieldItem) => {
    return value && option.id === value.id;
  };

  return (
    <div className="cselect">
      <div className="cselect__value" onClick={toggleSelect}>
        {placeholder && !value && (
          <p className="cselect__placeholder">{placeholder}</p>
        )}
        {value && <p className="cselect__selected">{value.title}</p>}
      </div>

      {isOpen && (
        <div className="cselect__options">
          <div className="cselect__options__wrapper">
            {options.map((option, index) => (
              <div
                key={index}
                className={`cselect__option ${
                  isSelected(option) ? "cselect__option--selected" : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {option.title}
                <CDevider />
              </div>
            ))}
          </div>

          <button className="cselect__btn" onClick={toggleSelect}>
            {t("button.cancel")}
          </button>
        </div>
      )}
    </div>
  );
};

export default CSelect;
