import React, { useEffect, useState } from "react";
import "./Login.css";
import pwLight from "../../../assets/images/pw-light.svg";
import pwDark from "../../../assets/images/pw-dark.svg";
import { observer } from "mobx-react-lite";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { authStore } from "../../../stores/authStore";
import CBack from "../../../components/CBack";
import { themeStore } from "../../../stores/themeStore";
import CInput from "../../../components/CInput";
import CButton from "../../../components/CButton";

interface IFormData {
  code: string;
}

const LoginConfirm: React.FC = observer(() => {
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    let interval: NodeJS.Timer | undefined;

    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isTimerActive, timer]);

  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    try {
      setIsLoading(true);
      const response = await authStore.loginConfirm(data.code);

      if (response.message) {
        setMessage(response.message);
        return;
      }

      if (response.token) {
        setTimeout(() => {
          navigate("/pincode");
        }, 1000);
      }
    } catch (error) {
      console.error("Error during login confirm:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login wrapper">
      <div style={{ width: "100%" }}>
        <CBack />
      </div>

      <img
        src={themeStore.isDarkTheme ? pwDark : pwLight}
        alt="pw"
        className="auth__logo"
      />

      <form className="login__form-confirm" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <p className="login__form__label">{t("code.title")}</p>
          <CInput
            placeholder={t("code.placeholder")}
            inputMode="numeric"
            maxLength={6}
            {...register("code", {
              required: t("error.codeRequired"),
              minLength: {
                value: 6,
                message: t("error.codeMinLength"),
              },
              maxLength: {
                value: 6,
                message: t("error.codeMaxLength"),
              },
            })}
          />

          {errors.code && (
            <span className="danger__text login__error">
              {errors.code.message}
            </span>
          )}

          {message && <p className="error__message">{message}</p>}
        </div>

        <p className="auth__timer">
          {timer} {t("labels.sec")}
        </p>

        <CButton
          title={t("labels.confirm")}
          variant="success"
          isLoading={isLoading}
        />
      </form>
    </div>
  );
});
export default LoginConfirm;
