import React, { useEffect, useState } from "react";
import "./Support.css";
import { useTranslation } from "react-i18next";
import CDevider from "../../../components/CDevider";
import { IContact } from "./IContact";
import { ticketStore } from "../../../stores/ticketStore";

const Support: React.FC = () => {
  const [contact, setContact] = useState<IContact>();
  const { t } = useTranslation();

  const fetchContact = async () => {
    try {
      const response: IContact = await ticketStore.getContacts();

      setContact(response);

      return response;
    } catch (error) {
      console.log("Error in fetchContact", error);
    }
  };

  useEffect(() => {
    fetchContact();
  }, []);

  return (
    <div className="support">
      <div className="support__contacts">
        <p className="contacts__title">{t("support.contactsTitle")}:</p>

        <div className="contact__phones">
          {contact?.phones.map((phone, index) => {
            return (
              <a key={index} href={`tel:${phone}`} className="contact__phone">
                {phone}
              </a>
            );
          })}
        </div>
      </div>

      <CDevider />

      <div className="support__socials">
        <p className="socials__title">{t("support.socialsTitle")}:</p>

        <div className="contact__socials">
          {contact?.socials.map((social, index) => {
            return (
              <a
                key={index}
                href={social.url}
                target="_blank"
                rel="noreferrer"
                className="socials__social"
              >
                <img src={social.icon} alt={social.title} />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Support;
