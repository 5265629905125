import React from "react";
import "./Favorite.css";
import { IFavoriteProps } from "./Favorite.types";
import { STATIC_URL } from "../../../../../constants/constant";
import { amountAddSpace } from "../../../../../utils/utils";
import { Link } from "react-router-dom";

const Favorite: React.FC<IFavoriteProps> = ({ favorite }) => {
  return (
    <Link
      to={`/payment/housefavorite/favoritePay/${favorite?.serviceId}`}
      className="favorite"
      state={{ favorite: JSON.stringify(favorite) }}
    >
      <img
        src={`${STATIC_URL}/img/services/${favorite?.serviceId}/100x100.webp`}
        alt={favorite?.note}
        className="favorite__img"
      />

      <div className="favorite__content">
        <p className="favorite__content__title">
          <span>{favorite?.note}</span>
          <span></span>
        </p>

        <p className="favorite__content__title">
          <span>Аккаунт</span>
          <span>{favorite?.account}</span>
        </p>

        <p className="favorite__content__title">
          <span>Сумма платежа</span>
          <span>{amountAddSpace(favorite?.amount)}</span>
        </p>
      </div>
    </Link>
  );
};

export default Favorite;
