import React, { useEffect, useState } from "react";
import "./History.css";
import SectionHeader from "../../../layouts/SectionHeader";
import { pointStore } from "../../../stores/pointStore";
import { t } from "i18next";

interface IAwardHistory {
  amount?: number;
  points?: number;
  type?: string;
  date?: string;
}

const History: React.FC = () => {
  const [awardsHistory, setAwardsHistory] = useState<IAwardHistory[]>([]);

  const getAwardsHistory = async () => {
    try {
      const response = await pointStore.awardsHistory();

      setAwardsHistory(response);
    } catch (error) {
      console.log("Error in getAwardsHistory", error);
    }
  };

  useEffect(() => {
    getAwardsHistory();
  }, []);

  return (
    <>
      <SectionHeader title={t("history.history")} />

      <div className="pointHistory wrapper">
        {awardsHistory?.length === 0 && (
          <p className="pointHistory__nohistory">{t("history.noHistory")}</p>
        )}

        {awardsHistory?.map((award: IAwardHistory, index: number) => {
          return (
            <div className="pointHistory__item" key={index}>
              <p className="pointHistory__item__type">{award?.type}</p>
              <p className="pointHistory__item__amount">+{award?.points}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default History;
