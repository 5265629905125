import React from "react";
import "./Monitoring.css";
import { useTranslation } from "react-i18next";
import SectionHeader from "../../layouts/SectionHeader";

const Monitoring = () => {
  const { t } = useTranslation();

  return (
    <>
      <SectionHeader title={t("profile.monitoring")} />

      <div className="monitoring wrapper"></div>
    </>
  );
};

export default Monitoring;
