import React, { useState } from "react";
import "./Login.css";
import pwLight from "../../../assets/images/pw-light.svg";
import pwDark from "../../../assets/images/pw-dark.svg";
import { observer } from "mobx-react-lite";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { authStore } from "../../../stores/authStore";
import CBack from "../../../components/CBack";
import { themeStore } from "../../../stores/themeStore";
import CInput from "../../../components/CInput";
import CButton from "../../../components/CButton";

interface IFormData {
  password: string;
}

const Login: React.FC = observer(() => {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    try {
      setIsLoading(true);
      const response = await authStore.login(data.password);

      if (response.message) {
        setMessage(response.message);
        return;
      }

      if (response.result) {
        navigate("/auth/login/confirm");
      }
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login wrapper">
      <div style={{ width: "100%" }}>
        <CBack />
      </div>

      <img
        src={themeStore.isDarkTheme ? pwDark : pwLight}
        alt="pw"
        className="auth__logo"
      />

      <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <p className="login__form__label">{t("password.title")}</p>

          <CInput
            type="password"
            placeholder={t("password.placeholder")}
            {...register("password", {
              required: t("error.passwordRequired"),
              minLength: {
                value: 6,
                message: t("error.passwordMinLength"),
              },
            })}
          />

          {errors.password && (
            <span className="danger__text login__error">
              {errors.password.message}
            </span>
          )}

          {message && <p className="error__message">{message}</p>}
        </div>

        <CButton title={t("labels.enter")} isLoading={isLoading} />
      </form>
    </div>
  );
});

export default Login;
