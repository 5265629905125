import React from "react";
import "./SectionHeader.css";
import { SectionHeaderProps } from "./SectionHeader.types";
import CBack from "../../components/CBack";

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, backTo }) => {
  return (
    <header className="section__header wrapper">
      {backTo ? <CBack to={backTo} /> : <CBack />}

      <p className="section__header__title">{title}</p>
    </header>
  );
};

export default SectionHeader;
