import React, { useEffect, useState } from "react";
import "./TransferConfirmSms.css";
import SectionHeader from "../../../layouts/SectionHeader";
import { themeStore } from "../../../stores/themeStore";
import pwDark from "../../../assets/images/pw-dark.svg";
import pwLight from "../../../assets/images/pw-light.svg";
import CInput from "../../../components/CInput";
import CButton from "../../../components/CButton";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { cardStore } from "../../../stores/cardStore";

interface IFormData {
  code: string;
}

const TransferConfirmSms: React.FC = () => {
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const state = location.state.response;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    let interval: NodeJS.Timer | undefined;

    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isTimerActive, timer]);

  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    try {
      setIsLoading(true);

      const response = await cardStore.confirmCardTransaction(
        Number(state.id),
        Number(data.code)
      );

      if (response.message) {
        setMessage(response.message);
        return;
      }

      if (response) {
        navigate(`/transfer/result/${state.id}`, { state: { response } });
      }
    } catch (error) {
      console.error("Error during transferConfirmSms confirm:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <SectionHeader backTo="/" />

      <div className="transferConfirmSms wrapper">
        <img
          src={themeStore.isDarkTheme ? pwDark : pwLight}
          alt="pw"
          className="auth__logo"
        />

        <form
          className="transferConfirmSms__form-confirm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <p className="transferConfirmSms__form__label">{t("code.title")}</p>
            <CInput
              placeholder={t("code.placeholder")}
              inputMode="numeric"
              maxLength={6}
              {...register("code", {
                required: t("error.codeRequired"),
                minLength: {
                  value: 6,
                  message: t("error.codeMinLength"),
                },
                maxLength: {
                  value: 6,
                  message: t("error.codeMaxLength"),
                },
              })}
            />

            {errors.code && (
              <span className="danger__text transferConfirmSms__error">
                {errors.code.message}
              </span>
            )}

            <p className="auth__timer">
              {timer} {t("labels.sec")}
            </p>

            <p className="error__message">{message}</p>
          </div>

          <CButton
            title={t("labels.confirm")}
            variant="success"
            isLoading={isLoading}
          />
        </form>
      </div>
    </>
  );
};

export default TransferConfirmSms;
