import React from "react";
import "./CBack.css";
import { CBackProps } from "./CBack.types";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";

const CBack: React.FC<CBackProps> = ({ to }) => {
  const navigate = useNavigate();

  return to ? (
    <Link to={to}>
      <IoIosArrowRoundBack className="cback__icon" />
    </Link>
  ) : (
    <div onClick={() => navigate(-1)}>
      <IoIosArrowRoundBack className="cback__icon" />
    </div>
  );
};

export default CBack;
