import { makeAutoObservable } from "mobx";
import apiClient from "../services/apiClient";

class TicketStore {
  constructor() {
    makeAutoObservable(this);
  }

  contactsCache = null;

  getContacts = async () => {
    if (this.contactsCache) {
      return this.contactsCache;
    }

    try {
      const response = await apiClient.get("/ticket/contacts");

      this.contactsCache = response.data;

      return response.data;
    } catch (error) {
      console.log("Error in getContacts", error);
    }
  };
}

export const ticketStore = new TicketStore();
