import React, { useEffect, useState, useCallback } from "react";
import "./Destination.css";
import { Link, useParams } from "react-router-dom";
import SectionHeader from "../../../layouts/SectionHeader";
import { paymentServiceStore } from "../../../stores/paymentServiceStore";
import { IPaymentService } from "../../../models/PaymentService";
import CCardSkeleton from "../../../components/CCardSkeleton";
import { STATIC_URL } from "../../../constants/constant";
import { useTranslation } from "react-i18next";

const Destination: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [services, setServices] = useState<IPaymentService[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const skeleton = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const fetchServices = useCallback(async () => {
    try {
      setIsLoading(true);

      if (id) {
        const response = await paymentServiceStore.getPaymentServiceByCategory(
          id
        );

        setServices(response);
      }
    } catch (error) {
      console.log("Error in fetchServices in Destination", error);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  return (
    <>
      <SectionHeader title={t("labels.services")} />

      <div className="destination wrapper">
        <div className="destination__container">
          {isLoading && skeleton.map((item) => <CCardSkeleton key={item} />)}

          {services?.map((service) => (
            <Link
              to={`/payment/service/${service.id}`}
              state={{ service: JSON.stringify(service) }}
              key={service.id}
              className="destination__item"
            >
              <img
                src={`${STATIC_URL}//img/services/${service.id}/100x100.webp`}
                alt={service.name}
                className="destination__item__img"
              />
              <p className="destination__item__name">{service.name}</p>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Destination;
