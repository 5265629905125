import React, { useEffect, useState } from "react";
import "./Payment.css";
import { useTranslation } from "react-i18next";
import SectionHeader from "../../layouts/SectionHeader";
import { paymentServiceStore } from "../../stores/paymentServiceStore";
import CCardSkeleton from "../../components/CCardSkeleton";
import { themeStore } from "../../stores/themeStore";
import { Link } from "react-router-dom";
import { IPaymentServiceCategory } from "../../models/IPaymentServiceCategory";

const AllCategories: React.FC = () => {
  const [categories, setCategories] = useState<IPaymentServiceCategory[]>([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const { t } = useTranslation();
  const skeleton = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const fetchCategories = async () => {
    try {
      setIsLoadingCategories(true);
      const response = await paymentServiceStore.getPaymentServiceCategories();

      setCategories(response);
    } catch (error) {
      console.log("Error in getPaymentCategories", error);
    } finally {
      setIsLoadingCategories(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <SectionHeader title={t("payment.allCategories")} />

      <div className="allCategories wrapper">
        <div className="allCategories__container">
          {isLoadingCategories &&
            skeleton.map((item) => <CCardSkeleton key={item} />)}

          {categories?.map((category) => (
            <Link
              to={`/payment/destination/${category.id}`}
              key={category.id}
              className="payment__service__item"
            >
              <img
                src={
                  themeStore.theme === "light"
                    ? category.img
                    : category.img_white
                }
                alt={category.name}
                className="payment__service__item__img"
              />
              <p className="payment__service__item__name">{category.name}</p>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default AllCategories;
