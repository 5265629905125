import { makeAutoObservable } from "mobx";
import apiClient from "../services/apiClient";

class NotificationStore {
  notificationsCache = null;
  cacheCount = null;

  constructor() {
    makeAutoObservable(this);
  }

  getNotifications = async () => {
    if (this.notificationsCache) {
      return this.notificationsCache;
    }

    try {
      const response = await apiClient.get("/notifications");

      this.notificationsCache = response.data;

      return response.data;
    } catch (error) {
      console.log("Error in getAccountInfo", error);
    }
  };

  unreadCount = async () => {
    if (this.cacheCount) {
      return this.cacheCount;
    }

    try {
      const response = await apiClient.get("/notifications/unreadCount");

      this.cacheCount = response.data;

      return response.data;
    } catch (error) {
      console.log("Error in getAccountInfo", error);
    }
  };

  allRead = async () => {
    try {
      const response = await apiClient.patch("/notifications/allRead");

      this.cacheCount = null;

      return response.data;
    } catch (error) {
      console.log("Error in allRead", error);
    }
  };
}

export const notificationStore = new NotificationStore();
