import React from "react";
import Router from "./routes/Router";
import useTelegramExpand from "./utils/hooks/useTelegramExpand";

const App: React.FC = () => {
  useTelegramExpand();

  return <Router />;
};

export default App;
