import { makeAutoObservable } from "mobx";

export type Theme = "light" | "dark";

class ThemeStore {
  theme: Theme;

  constructor() {
    makeAutoObservable(this);
    const deviceDefaultTheme = window.matchMedia?.("(prefers-color-scheme: dark)").matches;

    this.theme = (localStorage.getItem("theme") as Theme) || (deviceDefaultTheme ? "dark" : "light");

    localStorage.setItem("theme", this.theme);

    this.applyTheme();
  }

  toggleTheme = () => {
    this.theme = this.theme === "light" ? "dark" : "light";

    localStorage.setItem("theme", this.theme);

    this.applyTheme();
  };

  applyTheme() {
    document.body.setAttribute("data-theme", this.theme);
  }

  get isDarkTheme(): boolean {
    return this.theme === "dark";
  }
}

export const themeStore = new ThemeStore();
