import React, { useCallback, useEffect, useState } from "react";
import "./ServiceResult.css";
import SectionHeader from "../../../../layouts/SectionHeader";
import { Link, useLocation } from "react-router-dom";
import { themeStore } from "../../../../stores/themeStore";
import pwDark from "../../../../assets/images/pw-dark.svg";
import pwLight from "../../../../assets/images/pw-light.svg";
import CButton from "../../../../components/CButton";
import { useTranslation } from "react-i18next";
import CLoading from "../../../../components/CLoading";
import CStatusTicket from "../../../../components/CStatusTicket";
import CDevider from "../../../../components/CDevider";
import { payStore } from "../../../../stores/payStore";
import { cardStore } from "../../../../stores/cardStore";
import { historyStore } from "../../../../stores/historyStore";

interface IField {
  note: string;
  value: string;
}

export interface ITicket {
  id: number;
  amount: number;
  amountCommission: number;
  canceled: boolean;
  favouriteSaved: boolean;
  fields: IField[];
}

const ServiceResult: React.FC = () => {
  const [ticket, setTicket] = useState<ITicket>();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state.response;

  const getTicket = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await payStore.ticket(state.optoken);
      setTicket(response);
    } catch (error) {
      console.log("Error in getTicket", error);
    } finally {
      setIsLoading(false);
    }
  }, [state.optoken]);

  useEffect(() => {
    getTicket();
  }, [getTicket]);

  useEffect(() => {
    cardStore.reGetList();
    historyStore.reGetHistory();

    setTimeout(() => {
      cardStore.getTotalAmount();
    }, 2000);
  }, []);

  return (
    <>
      <SectionHeader title={t("transaction.resultTitle")} backTo="/" />

      <div className="serviceresult wrapper">
        <div>
          <div
            className="center"
            style={{ flexDirection: "column", gap: "10px" }}
          >
            <img
              src={themeStore.isDarkTheme ? pwDark : pwLight}
              alt="pw"
              className="serviceresult__img"
            />

            <div className="center">{isLoading && <CLoading />}</div>
          </div>

          {!isLoading && (
            <div className="serviceresult__fields">
              <CStatusTicket status="done" />

              <CDevider />

              {ticket?.fields?.map((field, index) => {
                return (
                  <div key={index} className="serviceresult__field">
                    <p className="serviceresult__note">{field.note}</p>
                    <p className="serviceresult__value">{field.value}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="serviceresult__actions">
          <CButton title={t("button.share")} />

          <Link to={"/payment"} className="serviceresult__btn">
            <CButton title={t("button.goHome")} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default ServiceResult;
