import React, { useEffect, useState } from "react";
import "./ConfirmVirtualCard.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SectionHeader from "../../../../layouts/SectionHeader";
import { themeStore } from "../../../../stores/themeStore";
import CInput from "../../../../components/CInput";
import CButton from "../../../../components/CButton";
import pwDark from "../../../../assets/images/pw-dark.svg";
import pwLight from "../../../../assets/images/pw-light.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { cardStore } from "../../../../stores/cardStore";

interface IFormData {
  code: string;
}

const ConfirmVirtualCard: React.FC = () => {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(60);
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>();

  const onConfirm = async (data: IFormData) => {
    try {
      setIsLoading(true);
      const response = await cardStore.confirmVirtualCard(
        state?.id,
        Number(data?.code)
      );

      if (response.message) {
        setMessage(response.message);
        return;
      }

      navigate("/payment");
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  return (
    <>
      <SectionHeader title={t("labels.confirmSms")} />

      <div className="confirmVirtualCard wrapper">
        <div>
          <div className="confirmVirtualCard__pw__container">
            <img
              src={themeStore.isDarkTheme ? pwDark : pwLight}
              alt="pw"
              className="confirmVirtualCard__pw"
            />
          </div>

          <div className="confirmVirtualCard__content">
            <p className="confirmVirtualCard__text confirmVirtualCard__title">
              {t("code.title")}
            </p>

            <CInput
              maxLength={6}
              inputMode="numeric"
              placeholder={t("code.placeholder")}
              {...register("code", {
                required: t("error.codeRequired"),
                minLength: {
                  value: 6,
                  message: t("error.codeMinLength"),
                },
                maxLength: {
                  value: 6,
                  message: t("error.codeMaxLength"),
                },
              })}
            />

            {errors.code && (
              <p className="confirmVirtualCard__text danger__text">
                {errors.code.message}
              </p>
            )}

            {message && (
              <p className="confirmVirtualCard__text error__message">
                {message}
              </p>
            )}

            <p className="confirmVirtualCard__timer">
              {timer} {t("labels.sec")}
            </p>
          </div>
        </div>

        <CButton
          variant="success"
          title={t("button.confirm")}
          onClick={handleSubmit(onConfirm)}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default ConfirmVirtualCard;
