import React from "react";
import "./Language.css";
import pwLight from "../../assets/images/pw-light.svg";
import pwDark from "../../assets/images/pw-dark.svg";
import CButton from "../../components/CButton";
import CSwitch from "../../components/CSwitch";
import { IoMoonOutline } from "react-icons/io5";
import { GoSun } from "react-icons/go";
import { themeStore } from "../../stores/themeStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CBack from "../../components/CBack";
import { LanguageProps } from "./Language.types";

const Language: React.FC<LanguageProps> = observer(({ navigateTo, backTo }) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="language wrapper">
      <div style={{ width: "100%" }}>
        {backTo ? <CBack to={backTo} /> : <CBack />}
      </div>

      <img
        src={themeStore.isDarkTheme ? pwDark : pwLight}
        alt="pw"
        className="language__logo"
      />

      <div className="language__content">
        <p className="language__title">{t("language.selectLanguage")}</p>

        <Link to={navigateTo || "/"}>
          <CButton title="English" onClick={() => changeLanguage("en")} />
        </Link>

        <Link to={navigateTo || "/"}>
          <CButton title="Русский" onClick={() => changeLanguage("ru")} />
        </Link>

        <Link to={navigateTo || "/"}>
          <CButton title="O'zbekcha" onClick={() => changeLanguage("uz")} />
        </Link>

        <div className="language__switch">
          <CSwitch
            checked={themeStore.isDarkTheme}
            onChange={themeStore.toggleTheme}
            startIcon={<GoSun />}
            endIcon={<IoMoonOutline />}
          />
        </div>
      </div>
    </div>
  );
});

export default Language;
