import React, { useEffect, useState } from "react";
import "./Header.css";
import { IoIosNotificationsOutline, IoMdRefresh } from "react-icons/io";
import { MdQrCodeScanner } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { notificationStore } from "../../stores/notificationStore";
import { observer } from "mobx-react-lite";
import { balanceStore } from "../../stores/balanceStore";
import { cardStore } from "../../stores/cardStore";
import { amountAddSpace } from "../../utils/utils";
import { BiSupport } from "react-icons/bi";
import { LuLoader } from "react-icons/lu";
import { t } from "i18next";

const Header: React.FC = observer(() => {
  const [unreadNotification, setUnreadNotification] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const balance = Number(cardStore.totalAmount.replaceAll(" ", "")).toFixed(2);

  const getUnreadNotification = async () => {
    try {
      const response = await notificationStore.unreadCount();

      setUnreadNotification(response?.result);
    } catch (error) {
      console.log("Error in getting unread notification", error);
    }
  };

  const refreshPage = () => {
    setIsRefreshing(true);

    window.location.reload();
  };

  useEffect(() => {
    getUnreadNotification();
  }, []);

  return (
    <header className="header wrapper">
      <div className="header__actions">
        <Link to={"/payment/notification"} className="header__notification">
          <IoIosNotificationsOutline />

          {unreadNotification > 0 && (
            <span className="header__notification-badge">
              {unreadNotification}
            </span>
          )}
        </Link>

        <div className="header__views" onClick={balanceStore.toggleBalance}>
          {balanceStore.showBalance ? <FaRegEyeSlash /> : <FaRegEye />}

          {balanceStore.showBalance && (
            <span className="header__balance">
              {amountAddSpace(Number(balance))} {t("balance.sum")}
            </span>
          )}
        </div>
      </div>

      <div className="header__actions">
        {isRefreshing ? (
          <LuLoader className="notificationMark__loader" />
        ) : (
          <IoMdRefresh onClick={refreshPage} />
        )}

        <a href="https://t.me/Tech_Payway" target="_blank" rel="noreferrer">
          <BiSupport />
        </a>

        <MdQrCodeScanner />

        <Link to={"/payment/search"}>
          <IoSearch />
        </Link>
      </div>
    </header>
  );
});

export default Header;
