import React from "react";
import "./CStatusIcon.css";
import { CStatusIconProps } from "./CStatusIcon.types";
import Silver from "../../assets/icons/silver.svg";
import Gold from "../../assets/icons/gold.svg";
import Platinum from "../../assets/icons/platinum.svg";

const CStatusIcon: React.FC<CStatusIconProps> = ({ status }) => {
  if (status === "Platinum") {
    return <img src={Platinum} alt="Platinum" />;
  }

  if (status === "Gold") {
    return <img src={Gold} alt="Gold" />;
  }

  return <img src={Silver} alt="Silver" />;
};

export default CStatusIcon;
