import React, { useEffect, useState } from "react";
import "./ActiveDevices.css";
import { useTranslation } from "react-i18next";
import SectionHeader from "../../layouts/SectionHeader";
import { accountStore } from "../../stores/accountStore";
import { observer } from "mobx-react-lite";
import { ISession } from "./ISession";
import { FiTrash } from "react-icons/fi";
import CLoading from "../../components/CLoading";

const ActiveDevices: React.FC = observer(() => {
  const [sessions, setSessions] = useState<ISession[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const fetchSessions = async () => {
    try {
      setIsLoading(true);
      const response: ISession[] = await accountStore.getAccountSessions();

      setSessions(response);
    } catch (error) {
      console.log("Error in fetchSessions", error);
    } finally {
      setIsLoading(false);
    }
  };

  const closeSession = async (sessionId: number) => {
    try {
      await accountStore.closeAccountSession(sessionId);

      setSessions(sessions.filter((session) => session.id !== sessionId));
    } catch (error) {
      console.log("Error in closeSession", error);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  return (
    <>
      <SectionHeader title={t("profile.activeDevices")} />

      <div className="active__devices wrapper">
        {isLoading && (
          <div className="active__devices__loading">
            <CLoading />
          </div>
        )}

        {sessions?.map((session) => {
          return (
            <div key={session.id} className="active__device__session">
              <div className="session__info">
                {session.current && (
                  <p className="session__text-current">
                    {t("session.current")}
                  </p>
                )}
                <p className="session__text">
                  {t("session.app")}: {session.app}
                </p>
                <p className="session__text">
                  {t("session.date")}: {session.created}
                </p>
                <p className="session__text">
                  {t("session.device")}: {session.device}
                </p>
                <p className="session__text">
                  {t("session.ip")}: {session.ip}
                </p>
                <p className="session__text">
                  {t("session.os")}: {session.os}
                </p>
              </div>

              {!session.current && (
                <button
                  className="session__disconnect"
                  onClick={() => closeSession(session.id)}
                >
                  {t("session.disconnect")} <FiTrash />
                </button>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
});

export default ActiveDevices;
