import image1 from "../assets/images/stories/image1.jpg";
import image2 from "../assets/images/stories/image2.jpg";
import image3 from "../assets/images/stories/image3.jpg";
import image9 from "../assets/images/stories/image9.jpg";
import image10 from "../assets/images/stories/image10.jpg";
import image11 from "../assets/images/stories/image11.jpg";
import gif1 from "../assets/images/stories/gif1.gif";
import gif2 from "../assets/images/stories/gif2.gif";

interface IStoryImage {
  type: string;
  url: string;
  duration: number;
}

export const storyImages: IStoryImage[] = [
  {
    type: "image",
    url: gif1,
    duration: 6000,
  },
  {
    type: "image",
    url: gif2,
    duration: 4000,
  },
  {
    type: "image",
    url: image1,
    duration: 3000,
  },
  {
    type: "image",
    url: image2,
    duration: 3000,
  },
  {
    type: "image",
    url: image3,
    duration: 3000,
  },
  {
    type: "image",
    url: image9,
    duration: 3000,
  },
  {
    type: "image",
    url: image10,
    duration: 3000,
  },
  {
    type: "image",
    url: image11,
    duration: 3000,
  },
];
