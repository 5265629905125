import React, { useEffect, useState } from "react";
import "./Search.css";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { IPaymentService } from "../../models/PaymentService";
import { paymentServiceStore } from "../../stores/paymentServiceStore";
import { STATIC_URL } from "../../constants/constant";
import CLoading from "../../components/CLoading";

const Search: React.FC = () => {
  const [search, setSearch] = useState("");
  const [services, setServices] = useState<IPaymentService[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const fetchServices = async () => {
    try {
      setIsLoading(true);

      const response = await paymentServiceStore.getPaymentServices();

      setServices(response);
    } catch (error) {
      console.log("Error in fetchServices", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredServices = services?.filter((service: IPaymentService) =>
    service.name?.toLowerCase().includes(search.toLowerCase())
  );

  const clearSearch = () => {
    setSearch("");
  };

  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <div className="search wrapper">
      <div className="search__input__container">
        <Link to="/payment">
          <MdOutlineKeyboardBackspace className="search__input__icon search__input__icon-start" />
        </Link>

        <input
          type="text"
          placeholder={t("labels.search")}
          className="search__input"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        {search && (
          <IoClose
            onClick={clearSearch}
            className="search__input__icon search__input__icon-end"
          />
        )}
      </div>

      <div className="search__content">
        {search && isLoading && (
          <div className="search__loading">
            <CLoading />
          </div>
        )}

        {search &&
          filteredServices?.map((service) => (
            <Link
              to={`/payment/service/${service.id}`}
              state={{ service: JSON.stringify(service) }}
              key={service.id}
              className="payment__service__item"
            >
              <img
                src={`${STATIC_URL}/img/services/${service.id}/100x100.webp`}
                alt={service.name}
                className="payment__service__item__img"
              />

              <p className="payment__service__item__name">{service.name}</p>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default Search;
