import React, { useEffect, useRef, useState } from "react";
import "./AddCard.css";
import SectionHeader from "../../layouts/SectionHeader";
import { useTranslation } from "react-i18next";
import CButton from "../../components/CButton";
import { cardStore } from "../../stores/cardStore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { STATIC_URL } from "../../constants/constant";
import CInput from "../../components/CInput";
import { useForm } from "react-hook-form";
import { useHookFormMask } from "use-mask-input";
import { useNavigate } from "react-router-dom";
import { GrNext, GrPrevious } from "react-icons/gr";

interface IFormValues {
  cardNumber: string;
  cardExpired: string;
  cardName: string;
}

const AddCard: React.FC = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>();
  const registerWithMask = useHookFormMask(register);

  const fetchImages = async () => {
    try {
      setIsLoadingImages(true);
      const response = await cardStore.getImages();

      setSelectedImage(response[0]);

      setImages(response);
    } catch (error) {
      console.log("Error in fetchImages", error);
    } finally {
      setIsLoadingImages(false);
    }
  };

  const onSubmit = async (data: IFormValues) => {
    const cardData = {
      cardNumber: data.cardNumber.replace(/\s/g, ""),
      expire: data.cardExpired.replace("/", ""),
      note: data.cardName,
      img: selectedImage,
      cvv: "",
      owner: "",
    };

    try {
      setIsLoading(true);
      const response = await cardStore.addCard(cardData);

      setErrorMessage(response.message);

      if (response.result) {
        navigate("/payment/addcard/confirm");
      }
    } catch (error) {
      console.log("Error in onSubmit", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSlideChange = (swiper: any) => {
    setSelectedImage(images[swiper.activeIndex]);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <>
      <SectionHeader title={t("labels.addCard")} />

      <div className="addcard wrapper">
        <div>
          <p className="addcard__text">{t("addCard.chooseImg")}</p>

          <div className="addcard__swiper">
            <span ref={prevRef} className="addcard__swiper__btn">
              <GrPrevious />
            </span>

            <Swiper
              onSlideChange={handleSlideChange}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              modules={[Navigation]}
            >
              {isLoadingImages && (
                <SwiperSlide className="card__loading addcard__slide"></SwiperSlide>
              )}

              {images?.map((image: string, index) => (
                <SwiperSlide key={index} className="addcard__slide">
                  <img
                    src={`${STATIC_URL}/img/cards/${image}`}
                    alt={image}
                    className="addcard__img"
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <span ref={nextRef} className="addcard__swiper__btn">
              <GrNext />
            </span>
          </div>

          <p className="addcard__text">{t("addCard.cardNumber")}</p>
          <CInput
            placeholder="____ ____ ____ ____"
            maxLength={19}
            inputMode="numeric"
            {...registerWithMask("cardNumber", ["9999 9999 9999 9999"], {
              required: t("labels.required"),
            })}
          />
          {errors.cardNumber && (
            <span className="danger__text addcard__error">
              {errors.cardNumber.message}
            </span>
          )}

          <p className="addcard__text">{t("addCard.cardExpired")}</p>
          <CInput
            placeholder={t("addCard.cardMMYY")}
            maxLength={5}
            inputMode="numeric"
            {...registerWithMask("cardExpired", ["99/99"], {
              required: t("labels.required"),
            })}
          />
          {errors.cardExpired && (
            <span className="danger__text addcard__error">
              {errors.cardExpired.message}
            </span>
          )}

          <p className="addcard__text">{t("addCard.cardName")}</p>
          <CInput
            placeholder={t("addCard.cardNamePlaceholder")}
            {...register("cardName", {
              required: t("labels.required"),
            })}
          />
          {errors.cardName && (
            <span className="danger__text addcard__error">
              {errors.cardName.message}
            </span>
          )}

          {errorMessage && <p className="error__message">{errorMessage}</p>}
        </div>

        <CButton
          title={t("addCard.add")}
          variant="success"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default AddCard;
