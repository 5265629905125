import { useEffect, useState } from "react";
import { cardStore } from "../../../stores/cardStore";
import { ICard } from "./ICard";

const useFetchCards = () => {
  const [cards, setCards] = useState<ICard[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCards = async () => {
      setIsLoading(true);

      try {
        let response = await cardStore.getList();

        const filteredCards = response.filter(
          (card: ICard) =>
            card.id !== "ofb" && card.id !== "sbp" && card.type !== "19"
        );
        setCards(filteredCards);
      } catch (error) {
        console.error("Error fetching cards:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCards();
  }, []);

  return { cards, isLoading };
};

export default useFetchCards;
