import React, { useState, forwardRef } from "react";
import "./CInput.css";
import { CInputProps } from "./CInput.types";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";

const CInput: React.FC<CInputProps> = forwardRef(
  (
    {
      value,
      onChange,
      placeholder,
      type = "text",
      variant,
      defaultCountry,
      disabled,
      required,
      style,
      className = "",
      maxLength,
      minLength,
      preLabel,
      inputMode,
      readonly,
      ...rest
    },
    ref
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
      setIsPasswordVisible(!isPasswordVisible);
    };

    return (
      <>
        {variant === "phone" ? (
          <PhoneInputWithCountrySelect
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            required={required}
            style={style}
            className={`cinput ${className}`}
            defaultCountry={defaultCountry}
          />
        ) : (
          <div className="cinput__container">
            {preLabel && (
              <p className="cinput__preLabel">
                {preLabel} <span>|</span>
              </p>
            )}

            <input
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              type={
                type === "password" && !isPasswordVisible ? "password" : "text"
              }
              disabled={disabled}
              required={required}
              style={style}
              className={`cinput ${className} cinput-${
                preLabel ? "preLabel" : ""
              }`}
              ref={ref}
              maxLength={maxLength}
              minLength={minLength}
              inputMode={inputMode}
              readOnly={readonly}
              {...rest}
            />
            {type === "password" && (
              <div className="cinput__icon">
                {isPasswordVisible ? (
                  <FaEyeSlash onClick={togglePasswordVisibility} />
                ) : (
                  <FaEye onClick={togglePasswordVisibility} />
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
);

export default CInput;
