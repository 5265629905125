import React from "react";
import { CSwitchProps } from "./CSwitch.types";
import "./CSwitch.css";

const CSwitch: React.FC<CSwitchProps> = ({
  checked,
  onChange,
  startIcon,
  endIcon,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange && onChange(event.target.checked);
  };

  return (
    <div className="cswitch__container">
      {startIcon && (
        <span className={`cswitch__icon ${!checked && "cswitch__icon-start"}`}>
          {startIcon}
        </span>
      )}

      <label className="cswitch">
        <input type="checkbox" checked={checked} onChange={handleChange} />
        <span className="cswitch__slider"></span>
      </label>
      
      {endIcon && (
        <span className={`cswitch__icon ${checked && "cswitch__icon-end"}`}>
          {endIcon}
        </span>
      )}
    </div>
  );
};

export default CSwitch;
