import React, { useCallback, useEffect, useState } from "react";
import "./TransferResult.css";
import { Link, useLocation } from "react-router-dom";
import SectionHeader from "../../../layouts/SectionHeader";
import { cardStore } from "../../../stores/cardStore";
import { useTranslation } from "react-i18next";
import CButton from "../../../components/CButton";
import { themeStore } from "../../../stores/themeStore";
import pwDark from "../../../assets/images/pw-dark.svg";
import pwLight from "../../../assets/images/pw-light.svg";
import CDevider from "../../../components/CDevider";
import CLoading from "../../../components/CLoading";
import { historyStore } from "../../../stores/historyStore";
import CStatusTicket from "../../../components/CStatusTicket";

interface IField {
  note: string;
  value: string;
}
export interface ITicket {
  id: number;
  amount: number;
  amountCommission: number;
  canceled: boolean;
  favouriteSaved: boolean;
  fields: IField[];
}

const TransferResult: React.FC = () => {
  const [ticket, setTicket] = useState<ITicket>();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const processCardTransaction = location.state.response;
  const { t } = useTranslation();

  const getTicket = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await cardStore.ticket(processCardTransaction.optoken);
      setTicket(response);
    } catch (error) {
      console.log("Error in getTicket", error);
    } finally {
      setIsLoading(false);
    }
  }, [processCardTransaction.optoken]);

  const reGetList = async () => {
    try {
      await cardStore.reGetList();
    } catch (error) {
      console.log("Error in reGetList", error);
    }
  };

  const reGetHistory = async () => {
    try {
      await historyStore.reGetHistory();
    } catch (error) {
      console.log("Error in reGetHistory", error);
    }
  };

  useEffect(() => {
    reGetList();
    reGetHistory();

    setTimeout(() => {
      cardStore.getTotalAmount();
    }, 2000);
  }, []);

  useEffect(() => {
    getTicket();
  }, [getTicket]);
  return (
    <>
      <SectionHeader title={t("transaction.resultTitle")} />

      <div className="transferresult wrapper">
        <div
          className="center"
          style={{ flexDirection: "column", gap: "10px" }}
        >
          <img
            src={themeStore.isDarkTheme ? pwDark : pwLight}
            alt="pw"
            className="transferresult__img"
          />

          <div className="center">{isLoading && <CLoading />}</div>
        </div>

        {!isLoading && (
          <div className="transferresult__fields">
            <CStatusTicket status="done" />

            <CDevider />

            {ticket?.fields?.map((field, index) => {
              return (
                <div key={index} className="transferresult__field">
                  <p className="transferresult__note">{field.note}</p>
                  <p className="transferresult__value">{field.value}</p>
                </div>
              );
            })}
          </div>
        )}

        <Link to={"/transfer"} className="transferresult__btn">
          <CButton title={t("button.goHome")} />
        </Link>
      </div>
    </>
  );
};

export default TransferResult;
