import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IHotNew } from "./IHotNew";
import { hotNewsStore } from "../../../stores/hotNewsStore";
import SectionHeader from "../../../layouts/SectionHeader";

const HotNewsDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [news, setNews] = useState<IHotNew>();

  useEffect(() => {
    const fetchHotNews = async () => {
      try {
        const response: IHotNew[] = await hotNewsStore.getHotNews();

        const news = response.find((news) => Number(news.id) === Number(id));

        setNews(news);
      } catch (error) {
        console.error("Error fetching hot news", error);
      }
    };

    fetchHotNews();
  }, [id]);

  return (
    <>
      <SectionHeader />

      <img
        src={news?.bigPicture}
        alt={news?.url}
        className="hotnews__detail_img"
      />
    </>
  );
};

export default HotNewsDetail;
