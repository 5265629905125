import { makeAutoObservable } from "mobx";
import apiClient from "../services/apiClient";
import { ICard } from "../pages/Payment/Cards/ICard";
import { amountAddSpace } from "../utils/utils";
import { TG_USER_ID } from "../constants/constant";

interface ICardData {
  cardNumber?: string;
  cvv?: string;
  note?: string;
  img?: string;
  owner?: string;
  expire?: string;
}

interface ICardNumber {
  id: string;
  number: string;
}

class CardStore {
  totalAmount = "0.00";
  cardId = "";
  cardsListCache = null;
  hasToken = localStorage.getItem(`token-${TG_USER_ID}`);
  cardsImageCache = null;
  transferDirectionsCache = null;
  cardNumbers: ICardNumber[] = [];

  constructor() {
    makeAutoObservable(this);

    setTimeout(() => {
      if (!!this.hasToken) {
        this.getTotalAmount();
      }
    }, 2000);
  }

  getList = async () => {
    if (this.cardsListCache) {
      return this.cardsListCache;
    }

    try {
      const response = await apiClient.get("/cards/list");

      this.cardsListCache = response.data;

      if (this.cardNumbers.length === 0) {
        response?.data.forEach(async (card: ICard) => {
          const response = await this.getCardNumber(String(card.id));

          this.cardNumbers.push({
            id: String(card?.id),
            number: String(response?.result),
          });
        });
      }
      return response.data;
    } catch (error) {
      console.log("Error in getCardList", error);
    }
  };

  reGetList = async () => {
    this.cardsListCache = null;

    return this.getList();
  };

  addCard = async (card: ICardData) => {
    try {
      const response = await apiClient.post("/cards/addCard", card);

      this.cardId = response.data.result;

      return response.data;
    } catch (error) {
      console.log("Error in addCard", error);
    }
  };

  addCardConfirm = async (code: string) => {
    try {
      const response = await apiClient.post("/cards/confirmAdd", {
        id: this.cardId,
        code: code,
      });

      return response.data;
    } catch (error) {
      console.log("Error in addCardConfirm", error);
    }
  };

  getImages = async () => {
    if (this.cardsImageCache) {
      return this.cardsImageCache;
    }

    try {
      const response = await apiClient.get("/cards/images");

      this.cardsImageCache = response.data;

      return response.data;
    } catch (error) {
      console.log("Error in getImages", error);
    }
  };

  getTotalAmount = async () => {
    try {
      let response = await this.getList();

      response = response.filter(
        (card: ICard) => card.id !== "ofb" && card.id !== "sbp"
      );

      if (response) {
        const cards = response;
        let totalAmount = 0;

        cards.forEach((card: ICard) => {
          !card?.block &&
            card?.active &&
            (totalAmount += Number(card.lastBalance));
        });

        this.totalAmount = amountAddSpace(totalAmount);
      }
    } catch (error) {
      console.log("Error in getTotalAmount", error);
    }
  };

  p2pInfo = async (cardNumber: string) => {
    try {
      const response = await apiClient.get(`/cards/p2pInfo/${cardNumber}`);

      return response.data;
    } catch (error) {
      console.log("Error in p2pInfo", error);
    }
  };

  createCardTransaction = async (receiver: string, amount: string) => {
    try {
      const response = await apiClient.post("/cards/createCardTransaction", {
        receiver: receiver,
        amount: Number(amount),
      });

      return response.data;
    } catch (error) {
      console.log("Error in createCardTransaction", error);
    }
  };

  processCardTransaction = async (transactionId?: number, cardId?: string) => {
    try {
      const response = await apiClient.post("/cards/processCardTransaction", {
        transactionId: transactionId,
        cardId: cardId,
      });

      return response.data;
    } catch (error) {
      console.log("Error in processCardTransaction", error);
    }
  };

  confirmCardTransaction = async (id: number, code: number) => {
    try {
      const response = await apiClient.post("/cards/confirmCardTransaction", {
        id: id,
        code: code,
      });

      return response.data;
    } catch (error) {
      console.log("Error in confirmCardTransaction", error);
    }
  };

  ticket = async (optoken: string) => {
    try {
      const response = await apiClient.post(`cards/ticket/${optoken}`);

      return response.data;
    } catch (error) {
      console.log("Error in ticket", error);
    }
  };

  getCardNumber = async (id: string) => {
    try {
      const response = await apiClient.get(`/cards/number/${id}`);

      return response.data;
    } catch (error) {
      console.log("Error in getCardNumber", error);
    }
  };

  transferDirections = async () => {
    if (this.transferDirectionsCache) {
      return this.transferDirectionsCache;
    }

    try {
      const response = await apiClient.get("/cards/transferDirections");

      this.transferDirectionsCache = response.data;
      return response.data;
    } catch (error) {
      console.log("Error in transferDirections", error);
    }
  };

  createVirtualCard = async () => {
    try {
      const response = await apiClient.post("/cards/createVirtualCard");

      return response.data;
    } catch (error) {
      console.log("Error in createVirtualCard", error);
    }
  };

  confirmVirtualCard = async (id: number, code: number) => {
    try {
      const response = await apiClient.post("/cards/confirmVirtualCard", {
        id,
        code,
      });

      return response.data;
    } catch (error) {
      console.log("Error in confirmVirtualCard", error);
    }
  };

  deleteCard = async (id: number) => {
    try {
      const response = await apiClient.delete(`/cards/${id}`);

      return response.data;
    } catch (error) {
      console.log("Error in deleteCard", error);
    }
  };

  clearCaches = () => {
    this.cardsListCache = null;
  };
}

export const cardStore = new CardStore();
