import React from "react";
import { useTranslation } from "react-i18next";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { LuClock8 } from "react-icons/lu";
import { MdOutlineCancel } from "react-icons/md";

interface HistoryCardStatusProps {
  status?: "paid" | "pending" | "cancelled" | string;
}

const HistoryCardStatus: React.FC<HistoryCardStatusProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <>
      {status === "paid" && (
        <div className="historyCard__status historyCard__status-paid">
          <IoIosCheckmarkCircleOutline />
          <span>{t("history.statusDone")}</span>
        </div>
      )}

      {status === "pending" && (
        <div className="historyCard__status historyCard__status-pending">
          <LuClock8 />
          <span>{t("history.statusPending")}</span>
        </div>
      )}

      {status === "cancelled" && (
        <div className="historyCard__status historyCard__status-cancelled">
          <MdOutlineCancel />
          <span>{t("history.statusCanseled")}</span>
        </div>
      )}
    </>
  );
};

export default HistoryCardStatus;
