import React from "react";
import "./CButton.css";
import { CButtonProps } from "./CButton.types";

const CButton: React.FC<CButtonProps> = ({
  title,
  onClick,
  disabled,
  variant = "primary",
  isLoading,
  style,
  className = "",
  type,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      style={style}
      className={`cbtn cbtn-${variant} ${className}`}
      type={type}
    >
      <span>{title}</span>
      {isLoading && <div className="cbtn__spinner"></div>}
    </button>
  );
};

export default CButton;
