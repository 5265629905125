import React, { useState } from "react";
import "./CCard.css";
import { CCardProps } from "./CCard.types";
import { STATIC_URL } from "../../constants/constant";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { balanceStore } from "../../stores/balanceStore";
import {
  amountAddSpace,
  formatCardNumber,
  formatExpDate,
} from "../../utils/utils";
import { PiCopyLight } from "react-icons/pi";
import { cardStore } from "../../stores/cardStore";
import { LuLoader } from "react-icons/lu";

const CCard: React.FC<CCardProps> = observer(({ card }) => {
  const [loadingNumber, setLoadingNumber] = useState(false);
  const { t } = useTranslation();

  const cardBgImage =
    card?.cardImg && card.cardImg !== "null"
      ? `${STATIC_URL}/img/cards/${card.cardImg}`
      : `${STATIC_URL}/img/cards/frame1.png`;

  const copyCardNumber = async (id: string) => {
    try {
      setLoadingNumber(true);

      const cardNumber = cardStore.cardNumbers.find((card) => card.id === id);

      if (cardNumber) {
        navigator.clipboard.writeText(cardNumber.number);
      }

      setTimeout(() => {
        setLoadingNumber(false);
      }, 300);
    } catch (error) {
      console.log("Error in copyCardNumber", error);
    }
  };

  return (
    <div
      className="ccard wrapper"
      style={{
        backgroundImage: `url(${cardBgImage})`,
      }}
    >
      <div className="ccard__header">
        <div>
          <img
            src={`${STATIC_URL}/img/cardtypes/${
              card?.cardType?.id || 5
            }/50x50.png`}
            alt={card.note}
            className="ccard__card__img"
          />

          <p className="ccard__note">{card.note}</p>
        </div>

        <img
          src={`${STATIC_URL}/img/banks/${
            card.cardType?.bankId || 0
          }/600x600.png`}
          alt={card.note}
          className="ccard__bank__img"
        />
      </div>

      {card.block && (
        <div className="ccard__blocked">
          <p>{t("labels.cardBlocked")}</p>
        </div>
      )}

      {card?.lastBalance !== -1 && (
        <div className="ccard__content">
          <p className="ccard__content__balance">
            {balanceStore.showBalance &&
              `${amountAddSpace(card.lastBalance || 0)} ${t("balance.sum")}`}
          </p>

          <p onClick={balanceStore.toggleBalance} className="ccard__eye">
            {balanceStore.showBalance ? <FaRegEyeSlash /> : <FaRegEye />}
          </p>
        </div>
      )}

      <div className="ccard__content ccard__content-bg">
        <div>
          <p className="ccard__content__number">
            {formatCardNumber(card.number || "")}

            {loadingNumber ? (
              <LuLoader className="ccard__loader" />
            ) : (
              <PiCopyLight
                className="ccard__copy"
                onClick={async () => await copyCardNumber(card.id || "")}
              />
            )}
          </p>
          <p>{card.ownerName}</p>
        </div>

        <div>
          <p>{formatExpDate(card.expDate || "")}</p>
        </div>
      </div>
    </div>
  );
});

export default CCard;
