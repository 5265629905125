import React, { useCallback, useEffect, useState } from "react";
import "./PaymentService.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IPaymentService } from "../../../models/PaymentService";
import SectionHeader from "../../../layouts/SectionHeader";
import { useTranslation } from "react-i18next";
import CChooseService from "../../../components/CChooseService";
import CButton from "../../../components/CButton";
import CInput from "../../../components/CInput";
import { useForm } from "react-hook-form";
import { amountAddSpace } from "../../../utils/utils";
import { useHookFormMask } from "use-mask-input";
import { IPaymentServiceField } from "../../../models/PaymentServiceField";
import CSelect from "../../../components/CSelect";
import { IServiceFieldItem } from "./PaymentService.types";
import { payStore } from "../../../stores/payStore";
import { paymentServiceStore } from "../../../stores/paymentServiceStore";

interface IAddField {
  id: number;
  name: string;
  val: string;
  alwref: boolean;
}

interface IFreqAccount {
  account: string;
  addFields: IAddField;
}

const PaymentService: React.FC = () => {
  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("");
  const [freqAmounts, setFreqAmounts] = useState<number[]>();
  const [freqAccounts, setFreqAccounts] = useState<IFreqAccount[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectValues, setSelectValues] = useState<{
    [key: string]: IServiceFieldItem | undefined;
  }>({});
  const location = useLocation();
  const service: IPaymentService = JSON.parse(location.state?.service);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();
  const registerWithMask = useHookFormMask(register);

  const onSubmit = async (data: any) => {
    const fieldData = Object.entries(data).reduce((acc, [key, value]) => {
      if (key.startsWith("field_")) {
        acc[key] = String(value).replace(/\s/g, "");
      }
      return acc;
    }, {} as Record<string, string>);

    const selectValuesArray = Object.entries(selectValues).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [`field_${key}`]: String(value?.id),
      }),
      {}
    );

    const { amount, account } = data;

    const requestBody = {
      serviceId: service.id || 0,
      amount: Number(String(amount).replace(/\s/g, "")),
      account: String(account).replace(/\s/g, ""),
      fields: {
        ...fieldData,
        ...selectValuesArray,
      },
    };

    try {
      setIsLoading(true);
      const response = await payStore.create(requestBody);

      if (response.message) {
        setMessage(response.message);
        return;
      }

      if (response) {
        navigate(`/payment/serviceConfirm/${response.id}`, {
          state: JSON.stringify(response),
        });
      }
    } catch (error) {
      console.log("Error in create", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/\D/g, "");

    setAmount(rawValue);
    setValue("amount", rawValue);
    trigger("amount");
  };

  const handleSelectChange = (fieldId: string, option: IServiceFieldItem) => {
    setSelectValues((prev) => ({
      ...prev,
      [fieldId]: option,
    }));
  };

  const selectFreqAccount = (account: IFreqAccount) => {
    setValue("account", account?.account);
    trigger("account");

    if (account.addFields) {
      Object.entries(account.addFields).forEach(([key, value]) => {
        setValue(`field_${key}`, value?.id);
        trigger(`field_${key}`);

        const selectValue: any = service?.fields?.find(
          (field) => field.id === Number(key)
        );

        const item = selectValue?.items?.find(
          (item: any) => item.id === value?.id
        );

        setSelectValues((prev) => {
          return {
            ...prev,
            [key]: {
              id: item?.id,
              title: item?.title,
            },
          };
        });
      });
    }
  };

  const selectFreqAmount = (amount: number) => {
    setAmount(String(amount));
    setValue("amount", String(amount));
    trigger("amount");
  };

  const getFrequentData = useCallback(async () => {
    try {
      const response = await paymentServiceStore.getPaymentFrequentData(
        service?.id
      );

      setFreqAmounts(response?.amounts);
      setFreqAccounts(response?.accounts);
    } catch (error) {
      console.log("Error in getFrequentData", error);
    }
  }, [service?.id]);

  useEffect(() => {
    getFrequentData();
  }, [getFrequentData]);

  return (
    <>
      <SectionHeader title={t("footer.payment")} />

      <form
        className="paymentService wrapper"
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <div className="paymentService__content">
          <CChooseService service={service} />

          <div className="paymentService__fields">
            {service?.fields?.map((field: IPaymentServiceField, index) => {
              return (
                <div className="paymentService__field" key={field.id}>
                  <p className="paymentService__field__title">{field?.title}</p>

                  {field?.type === "input" && (
                    <CInput
                      placeholder={field?.placeholder}
                      preLabel={field?.preLabel}
                      {...registerWithMask(
                        `field_${field.id}`,
                        [service?.displayMask?.replaceAll("X", "9") || ""],
                        {
                          required: t("labels.required"),
                        }
                      )}
                      className="paymentService__input-field"
                    />
                  )}

                  {field?.type === "select" && (
                    <CSelect
                      options={field?.items}
                      placeholder={field?.placeholder || t("labels.choose")}
                      value={selectValues[String(field.id)]}
                      onChange={(option) =>
                        handleSelectChange(String(field.id), option)
                      }
                    />
                  )}

                  {errors[`field_${field.id}`] && (
                    <p className="paymentService__field__error">
                      {t("labels.required")}
                    </p>
                  )}
                </div>
              );
            })}

            <div className="paymentService__field">
              <p className="paymentService__field__title">
                {service?.accountTitle}
              </p>

              <CInput
                placeholder={service?.accountPlaceholder}
                preLabel={service?.preLabel}
                className={`${service.isCard ? "cinput-card" : ""} ${
                  service?.fields && service?.fields?.length > 0
                    ? "paymentService__input-field"
                    : ""
                }`}
                inputMode={
                  service?.isCard || service?.onlyNums ? "numeric" : "text"
                }
                {...registerWithMask(
                  "account",
                  [
                    service?.isCard
                      ? "9999 9999 9999 9999"
                      : service?.displayMask?.replaceAll("X", "9") || "",
                  ],
                  {
                    required: t("labels.required"),
                  }
                )}
              />

              {errors.account && (
                <p className="paymentService__field__error">
                  {t("labels.required")}
                </p>
              )}
            </div>

            {freqAccounts && freqAccounts?.length > 0 && (
              <div className="paymentService__freqData">
                {freqAccounts?.map((freqAccount, index) => {
                  return (
                    <div
                      className="paymentService__freqItem"
                      key={index}
                      onClick={() => selectFreqAccount(freqAccount)}
                    >
                      {freqAccount.account}
                    </div>
                  );
                })}
              </div>
            )}

            <div className="paymentService__field">
              <p className="paymentService__field__title">
                {t("transfer.titleAmount")}
              </p>

              <input
                placeholder="0"
                className="paymentService__input"
                value={amountAddSpace(Number(amount))}
                inputMode="numeric"
                {...register("amount", {
                  required: t("labels.required"),
                  validate: {
                    min: (value) => {
                      if (Number(service.min) !== 0) {
                        const numValue = Number(value.replace(/\s/g, ""));
                        return (
                          numValue >= Number(service.min) ||
                          t("error.minAmount", { min: service.min })
                        );
                      }
                    },
                    max: (value) => {
                      if (Number(service.max) !== 0) {
                        const numValue = Number(value.replace(/\s/g, ""));
                        return (
                          numValue <= Number(service.max) ||
                          t("error.maxAmount", { max: Number(service.max) })
                        );
                      }
                    },
                  },
                })}
                onChange={handleAmountChange}
              />

              {freqAmounts && freqAmounts?.length > 0 && (
                <div className="paymentService__freqData">
                  {freqAmounts?.map((freqAmount, index) => {
                    return (
                      <div
                        className="paymentService__freqItem"
                        key={index}
                        onClick={() => selectFreqAmount(freqAmount)}
                      >
                        {freqAmount}
                      </div>
                    );
                  })}
                </div>
              )}

              {errors.amount && (
                <p className="paymentService__field__error">
                  {typeof errors.amount.message === "string"
                    ? errors.amount.message
                    : t("labels.required")}
                </p>
              )}

              {message && <p className="error__message">{message}</p>}
            </div>
          </div>
        </div>

        <div>
          <div className="paymentService__actions">
            <Link to={"/payment"} className="paymentService__link">
              <CButton title={t("button.goHome")} />
            </Link>

            <CButton
              title={t("button.resume")}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default PaymentService;
