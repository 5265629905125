import { makeAutoObservable } from "mobx";
import apiClient from "../services/apiClient";
import welcomeRu from "../assets/images/news/welcomeRu.jpg";
import welcomeUz from "../assets/images/news/welcomeUz.jpg";
import futureRu from "../assets/images/news/futureRu.jpg";
import futureUz from "../assets/images/news/futureUz.jpg";
import allRu from "../assets/images/news/allRu.jpg";
import allUz from "../assets/images/news/allUz.jpg";
import safetyRu from "../assets/images/news/safetyRu.jpg";
import safetyUz from "../assets/images/news/safetyUz.jpg";
import { IHotNew } from "../pages/Payment/HotNews/IHotNew";

const TG_USER_LANG = window.Telegram.WebApp.initDataUnsafe?.user?.language_code;

class HotNewsStore {
  hotNewsCache = null;

  constructor() {
    makeAutoObservable(this);
  }

  getHotNews = async () => {
    if (this.hotNewsCache) {
      return this.hotNewsCache;
    }

    try {
      const response = await apiClient.get("/news/mobile");

      const hotNews = response.data.map((item: IHotNew, index: number) => {
        switch (index) {
          case 0:
            return {
              ...item,
              bigPicture: TG_USER_LANG === "uz" ? welcomeUz : welcomeRu,
            };
          case 1:
            return {
              ...item,
              bigPicture: TG_USER_LANG === "uz" ? futureUz : futureRu,
            };
          case 2:
            return {
              ...item,
              bigPicture: TG_USER_LANG === "uz" ? safetyUz : safetyRu,
            };
          case 3:
            return {
              ...item,
              bigPicture: TG_USER_LANG === "uz" ? allUz : allRu,
            };
          default:
            return item;
        }
      });

      this.hotNewsCache = hotNews;

      return hotNews;
    } catch (error) {
      console.log("Error in getAccountInfo", error);
    }
  };

  clearCaches = () => {
    this.hotNewsCache = null;
  };
}

export const hotNewsStore = new HotNewsStore();
