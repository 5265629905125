import { useTranslation } from "react-i18next";

export const useTypeCountryResidenceOptions = () => {
  const { t } = useTranslation();

  const typeCountryResidenceOptions = [
    { id: 1, title: t("country.uzbekistan"), value: "UZ" },
    { id: 2, title: t("country.russia"), value: "RU" },
    { id: 3, title: t("country.azerbaijan"), value: "AZ" },
    { id: 4, title: t("country.armenia"), value: "AM" },
    { id: 5, title: t("country.belarus"), value: "BY" },
    { id: 6, title: t("country.georgia"), value: "GE" },
    { id: 7, title: t("country.kazakhstan"), value: "KZ" },
    { id: 8, title: t("country.kyrgyzstan"), value: "KG" },
    { id: 9, title: t("country.moldova"), value: "MD" },
    { id: 10, title: t("country.tajikistan"), value: "TJ" },
    { id: 11, title: t("country.turkey"), value: "TR" },
    { id: 13, title: t("country.ukraine"), value: "UA" },
  ];

  return typeCountryResidenceOptions;
};
