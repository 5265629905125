import React, { useEffect, useState } from "react";
import "./History.css";
import { historyStore } from "../../stores/historyStore";
import { useTranslation } from "react-i18next";
import { IHistory } from "../../models/IHistory";
import HistoryCard from "./HistoryCard";
import ExpenseOrProfit from "./ExpenseOrProfit";
import CLoading from "../../components/CLoading";

const History: React.FC = () => {
  const [histories, setHistories] = useState<IHistory>();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const fetchHistories = async () => {
    try {
      setIsLoading(true);
      const response = await historyStore.getHistory();

      setHistories(response);
    } catch (error) {
      console.log("Error in fetchHistories", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHistories();
  }, []);

  return (
    <div className="history wrapper">
      <ExpenseOrProfit />

      {/* <FilterCard /> */}

      <div className="historyCard__container">
        {isLoading && (
          <div className="historyCard__loading">
            <CLoading />
          </div>
        )}

        {histories?.items.length === 0 && !isLoading && (
          <p className="history__empty">{t("history.empty")}</p>
        )}

        {histories?.items.map((item) => {
          return <HistoryCard history={item} key={item.id} />;
        })}
      </div>
    </div>
  );
};

export default History;
