import React, { useState } from "react";
import "./CreateVirtualCard.css";
import SectionHeader from "../../../layouts/SectionHeader";
import { useTranslation } from "react-i18next";
import humo from "../../../assets/images/humo-icon.png";
import CButton from "../../../components/CButton";
import { Link, useNavigate } from "react-router-dom";
import { cardStore } from "../../../stores/cardStore";

const CreateVirtualCard: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const createVirtualCard = async () => {
    try {
      setIsLoading(true);
      const response = await cardStore.createVirtualCard();

      if (response.message) {
        setMessage(response.message);

        return;
      }

      navigate("/payment/confirmVirtualCard", { state: response });
    } catch (error) {
      console.log("Error in createVirtualCard", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SectionHeader title={t("virtualCard.create")} />

      <div className="virtualCard wrapper">
        <div>
          <div className="virtualCard__content">
            <div className="virtualCard__content__header">
              <div>
                <h3 className="virtualCard__content__header__title">
                  Humo Virtual
                </h3>
                <h4 className="virtualCard__content__header__subtitle">
                  {t("virtualCard.cardConditions")}
                </h4>
              </div>

              <img src={humo} alt="humo" className="vitualCard__content__img" />
            </div>

            <div className="virtualCard__content__main">
              <div className="virtualCard__content__item">
                <p className="virtualCard__content__field">
                  {t("virtualCard.cardIssue")}
                </p>
                <p className="virtualCard__content__value">
                  {t("virtualCard.free")}
                </p>
              </div>

              <div className="virtualCard__content__item">
                <p className="virtualCard__content__field">
                  {t("virtualCard.cardMaintenance")}
                </p>
                <p className="virtualCard__content__value">
                  {t("virtualCard.free")}
                </p>
              </div>

              <div className="virtualCard__content__item">
                <p className="virtualCard__content__field">
                  {t("virtualCard.cardDeposit")}
                </p>
                <p className="virtualCard__content__value">
                  {t("virtualCard.none")}
                </p>
              </div>

              <div className="virtualCard__content__item">
                <p className="virtualCard__content__field">
                  {t("virtualCard.cardValidity")}
                </p>
                <p className="virtualCard__content__value">
                  {t("virtualCard.validityTime")}
                </p>
              </div>

              <div className="virtualCard__content__item">
                <p className="virtualCard__content__field">
                  {t("virtualCard.cardValidity")}
                </p>
                <p className="virtualCard__content__value">
                  {t("virtualCard.uzs")}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="virtualCard__ofert">
              {t("virtualCard.modalTitleVitualCard")}{" "}
              <a href="/" target="_blank">
                {t("virtualCard.modalTitleOfert")}
              </a>
            </p>
          </div>
        </div>

        <div>
          <p className="error__message">{message}</p>

          {message && (
            <div className="virtualCard__completeIdent">
              <Link to="/profile/ident">{t("ident.completeIdent")}</Link>
            </div>
          )}
          <CButton
            variant="success"
            title={t("button.create")}
            isLoading={isLoading}
            onClick={createVirtualCard}
          />
        </div>
      </div>
    </>
  );
};

export default CreateVirtualCard;
