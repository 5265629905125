import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import { MdPayment } from "react-icons/md";
import { BiTransfer } from "react-icons/bi";
import { MdOutlineHistory } from "react-icons/md";
import { LuUserCircle } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import coin from "../../assets/images/coin.png";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer wrapper">
      <NavLink
        to={"/payment"}
        className={({ isActive }) =>
          isActive ? "footer__link footer__link-active" : "footer__link"
        }
      >
        <MdPayment className="footer__icon" />
        <span className="footer__text">{t("footer.payment")}</span>
      </NavLink>

      <NavLink
        to={"/transfer"}
        className={({ isActive }) =>
          isActive ? "footer__link footer__link-active" : "footer__link"
        }
      >
        <BiTransfer className="footer__icon" />
        <span className="footer__text">{t("footer.transfers")}</span>
      </NavLink>

      <NavLink
        to={"/point"}
        className={({ isActive }) =>
          isActive ? "footer__link footer__link-active" : "footer__link"
        }
      >
        <img src={coin} alt="coin" className="footer__icon"/>
        <span className="footer__text">{t("footer.bonuses")}</span>
      </NavLink>

      <NavLink
        to={"/history"}
        className={({ isActive }) =>
          isActive ? "footer__link footer__link-active" : "footer__link"
        }
      >
        <MdOutlineHistory className="footer__icon" />
        <span className="footer__text">{t("footer.history")}</span>
      </NavLink>

      <NavLink
        to={"/profile"}
        className={({ isActive }) =>
          isActive ? "footer__link footer__link-active" : "footer__link"
        }
      >
        <LuUserCircle className="footer__icon" />
        <span className="footer__text">{t("footer.profile")}</span>
      </NavLink>
    </footer>
  );
};

export default Footer;
