import { makeAutoObservable } from "mobx";

class BalanceStore {
  showBalance = JSON.parse(localStorage.getItem("showBalance") || "false");

  constructor() {
    makeAutoObservable(this);
  }

  toggleBalance = () => {
    this.showBalance = !this.showBalance;

    localStorage.setItem("showBalance", JSON.stringify(this.showBalance));
  };
}

export const balanceStore = new BalanceStore();
